html.a11y-vision {

    .page-zal {

        .white-block,
        .black-block {
            width: 100%;
            left: 0;
            margin: 0;
            padding: 0;
            transform: none;

            > .block {
                width: min(1100px, 100%);
                margin-left: auto;
                margin-right: auto;
            }
        }

        .history-6__left,
        .history-6__right {

            .h1 {
                color: currentColor !important;
            }

            > * {
                max-width: 100%;
                color: currentColor !important;
            }
        }

        .inner {

            img {
                border-radius: 1em;
            }
        }

        .header__logo {

            img {
                border-radius: 0 !important;
            }
        }

        .history-img-row {
            margin-bottom: 2em;
        }

        h3, .h3 {
            font-size: 1.3em;
            font-weight: bold;
        }
    }

    .another-item {
        aspect-ratio: unset;
        padding: 0;
        border-radius: 0;
        overflow: visible;
        background: none;
        display: block;
    }

    .another-item__img-wrap {
        display: none;
    }

    .another-item__link {
        display: inline-block;
        padding: 0;

        &:before,
        &:after {
            content: none;
        }
    }
}
