html.a11y-vision {

    .rules-grid {
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .rule-preview {
        aspect-ratio: unset;
        padding: 0;
        border-radius: 0;
        overflow: visible;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 0;
    }

    .rule-preview__link {
        padding: 0;
        font-size: var(--av-font-size);

        &:before,
        &:after {
            content: none;
        }
    }
}
