.cards-preview-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;
    @media (max-width: 1600px) {
        gap: 16px;
    }
    @media (max-width: 992px) {
        gap: 12px;
    }

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
}
.card-preiview {
    aspect-ratio: 4/3;
    padding: 30px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    overflow: hidden;
    &__img-wrap {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 2;
            object-fit: cover;
            transition: transform 0.6s;
            .card-preiview:hover & {
                transform: scale(1.02);
            }
        }
        &::before {
            background: rgba(0, 0, 0, 0.2);
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            display: block;
            position: absolute;
            z-index: 3;
        }
        &::after {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.8) 0%,
                rgba(28, 28, 28, 0) 100%
            );
            width: 100%;
            content: "";
            height: 80%;
            left: 0;
            bottom: 0;
            display: block;
            position: absolute;
            z-index: 3;
        }
    }
    &__link {
        position: relative;
        z-index: 5;
        display: block;
        color: #fff;
        .clampf(13,24);
        font-family: Unbounded;
        font-weight: 500;
        &::before {
            width: 100vmax;
            height: 100vmax;
            display: block;
            content: "";
            left: 50%;
            top: 50%;
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
        }
        &::after {
            display: block;
            content: "";
            width: 32px;
            height: 32px;
            position: absolute;
            right: 0;
            top: 0;
            transition: transform 0.6s;
            .card-preiview:hover & {
                transform: rotate(45deg);
            }
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.33594 14.6654L14.6693 1.33203M14.6693 1.33203V14.132M14.6693 1.33203H1.86927' stroke='white' stroke-width='2'/%3E%3Cpath d='M14.6672 14.132V1.33203H1.86719' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
        }
    }
}

