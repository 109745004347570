.hp-quiz {
    aspect-ratio: 2.3;
    background-color: var(--dark);
    color: #fff;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    margin-top: 80px;
    opacity: 0;
    // transform: scale(1.02);
    transition: all 0.6s ease;

    @media (max-width: @screen-l) {
        aspect-ratio: 1.12;
        border-radius: 30px;
        margin-top: 60px;
    }

    @media (max-width: @screen-s) {
        aspect-ratio: unset;
        padding-top: 50px;
        padding-bottom: 40px;
    }

    &._is-animated {
        // transform: scale(1);
        opacity: 1;
    }
}

.hp-quiz__inner {
    position: relative;
    max-width: 80%;
    z-index: 2;
    margin: 0 auto;
    padding-top: 20px;

    @media (max-width: @screen-l) {
        max-width: 70%;
    }

    @media (max-width: @screen-s) {
        max-width: 94%;
    }
}

.hp-quiz__logo {
    width: 96px;
    height: 71px;
}

.hp-quiz__title {
    margin-top: 24px;
}

.hp-quiz__text {
    margin: 10px 0;
    font-size: 18px;
    line-height: 1.33;

    @media (max-width: @screen-m) {
        font-size: 16px;
    }
}

.hp-quiz__btn {
    display: inline-block;
    white-space: nowrap;
    padding: 0 20px;
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    border: 0;
    border-radius: 50px;
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.16px;
    transition: all 0.2s ease;
    margin-top: 12px;
    background: var(--gradient);
    overflow: hidden;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 1;
        background: #fff;
        opacity: 0;
        transition: inherit;
    }

    html.desktop &:hover {

        &:before {
            opacity: 1;
        }

        svg {
            color: #fb8129;
        }

        span {
            .color();
        }
    }

    svg {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        z-index: 2;
        width: 24px;
        height: 24px;
        margin-right: 4px;
    }

    span {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        z-index: 2;
    }
}

.hp-quiz__img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    transform: scale(1.1);
    transition: transform 6s ease-out;

    .hp-quiz._is-animated & {
        transform: scale(1);
    }
}
