.breadcrumbs {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-bottom: 30px;
    padding: 0;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: calc(100% + (var(--inner-padding) * 2));
    margin-left: calc(-1 * var(--inner-padding));
    margin-right: calc(-1 * var(--inner-padding));
    user-select: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    &::before,
    &::after {
        min-width: var(--inner-padding);
        height: 6px;
        display: block;
        content: "";
    }
    li {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 136%;
        color: var(--gray);
        white-space: nowrap;
        &:last-child::after {
            display: none;
        }
        a {
            color: var(--link);

            html.desktop &:hover {
                color: var(--hover-red);
            }
        }

        &::after {
            display: inline-block;
            content: "/";
            color: var(--link);
            margin-left: 8px;
            margin-right: 8px;
        }
    }
}
