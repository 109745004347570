html.a11y-vision {

    .droplist__list {
        border: 1px solid var(--av-color-text);

        li {
            font-size: var(--av-font-size);
        }
    }
}
