html.a11y-vision {

    body:has(.lections-list) {

        .white-block,
        .black-block {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 3em;

            > div {
                width: min(1100px, 100%);
                margin-left: auto;
                margin-right: auto;
            }

            
        }

        .white-block,
        .black-block {
            width: 100%;
            left: 0;
            margin: 0;
            padding: 0;
            transform: none;

            > div {
                width: min(1100px, 100%);
                margin-left: auto;
                margin-right: auto;
            }

            .history-bg-block {
                margin-bottom: 0;
            }
        }

        .history-img-row {
            margin-bottom: 0;
        }

        img {
            border-radius: 1em !important;
        }

        .history-bg-block__content {
            min-height: 0;
            padding-left: 0 !important;
            padding-right: 0 !important;
    
            @media (max-width: 1400px) {
                padding-left: var(--inner-padding) 0 !important;
                padding-right: var(--inner-padding) 0 !important;
            }
        }
    }

    .video {
        display: none;
    }

    .text ul.lections-list,
    .lections-list {
        grid-template-columns: 1fr !important;
        display: grid !important;

        li {
            padding: 0;
            border-radius: 0;
            overflow: visible;
        }
    }

    .svg-light {
        display: none;
    }

    .lections-prices__filters {

        .overfolow-x__size {
            display: flex;
            flex-wrap: wrap;
            overflow: visible;
        }
    }

    .lections-prices__filter {
        font-size: var(--av-font-size);
        border: 1px solid transparent;

        &._active {
            background: var(--av-color-text);
        }

        &:hover {
            .ally-vision-hover();
        }
    }

    .lections-prices__body {
        padding: 0;
    }

    .lections-price__tags {
        flex-direction: column;
        gap: 0.5em;

        li {
            font-size: var(--av-font-size);

            [class^="icon-"] {
                width: 1.2em;
                height: 1.2em;
                
                svg {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }

    .lections-prices__label {
        position: relative;
        width: 100%;
    }

    .book-widget__day-title {
        font-size: var(--av-font-size);
    }

    .book-widget__day {
        font-size: var(--av-font-size);
        box-shadow: none;
        border: 1px solid var(--av-color-border);

        &._active {
            background: var(--av-color-text);
        }

        &:hover {
            .ally-vision-hover();
        }
    }

    .book-widget__item-link {
        font-size: var(--av-font-size);
        text-decoration: none;
        background: none;
        border: 1px solid var(--av-color-border);

        &:hover {
            .ally-vision-hover();
        }
    }

    .book-widget__item-price {
        font-size: var(--av-font-size);
    }

    .lections-prices__label {
        padding: 1.2em;
        border-radius: 1em;
        border: 2px solid var(--av-color-border);
        box-sizing: border-box;
        background: none;
        height: auto;
        color: var(--av-color-text);
        font-size: var(--av-font-size);
        margin-top: 2em;

        @media (max-width: @screen-s) {
            padding: 0.8em;
        }

        span {
            font-size: var(--av-font-size);
        }

        a {
            font-size: var(--av-font-size);
        }
    }
}
