html.a11y-vision {

    .preloader,
    .hp-space,
    .hp-video,
    .hp-quiz {
        display: none;
    }

    .page-home {
        background: var(--av-color-bg);
        color: var(--av-color-text);

        .event-preview,
        .news-card {
            opacity: 1 !important;
            transform: none !important;
            transition: none !important;
        }

        .white-block,
        .black-block {
            width: 100%;
            left: 0;
            margin: 0;
            padding: 0;
            transform: none;

            > .block {
                width: min(1100px, 100%);
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .hp-promo,
    .hp-promo__titles,
    .hp-promo__title,
    .hp-promo__subtitle {
        background: none;
        color: var(--av-color-text);
        position: relative;
        transform: none !important;
        opacity: 1 !important;
        transition: none !important;
        top: 0 !important;
        text-align: left;

        > * {
            transform: none !important;
            opacity: 1 !important;
            transition: none !important;
        }
    }

    .hp-promo {
        width: 100%;
        left: 0;
        margin: 0;

        .inner {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .hp-promo__title {
        margin-bottom: 0;
    }

    .hp-promo__subtitle {
        width: 100%;
        max-width: 100%;
    }

    .hp-promo__buy-ticket {
        text-decoration: none;
        font-size: var(--av-font-size);
        padding: 0.5em 1em;
        background: none;
        border: 1px solid var(--av-color-border);
        display: none;

        &:hover {
            .ally-vision-hover();
        }
        
        &:before,
        &:after {
            content: none;
        }

        svg {
            display: none;
        }

        span {
            background: none;
            color: var(--av-color-text) !important;
            -webkit-text-fill-color: unset;
        }
    }

    .hp-program {
        margin-top: 2em;
    }

    .hp-program__inner {
        display: flex;
        flex-direction: column;
    }

    .hp-program__cell {
        background: none;

        &:has(.hp-program__load) {
            display: none;
        }
    }

    .hp-program__load {

    }

    .hp-program__card {
        padding: 0;
        border-radius: 0;
        overflow: visible;
        transform: none !important;
        opacity: 1 !important;
        transition: none !important;
    }

    .hp-program__card-inner {
        position: relative;
        padding: 0;
        display: block;
    }

    .hp-program__tickets {
        background: none;
        display: inline-block;
        width: auto;
        transition: none;
    }
    
    .hp-program__tickets-title,
    .hp-program__events-title {
        font-size: var(--av-font-size);
        font-weight: bold;
    }

    .hp-program__events {
        display: inline-block;
        width: auto;
        transition: none;
    }

    .hp-program__events-animation {
        display: none;
    }

    .hp-program__program-orbits,
    .hp-program__program-hovers {
        display: none;
    }

    .hp-program__program {
        background: none;

        .hp-program__card-inner {
            min-height: 0;
        }
    }

    .hp-program__program-title {
        font-size: var(--av-font-size);
        font-weight: bold;
        margin-top: 1em;
    }

    .hp-program__list {
        max-width: 100%;
        gap: 0.5em 1em;
        margin-top: 0.5em;

        a {
            font-size: var(--av-font-size);
            font-weight: normal;
            transition: none;
            background: none;
            color: var(--av-color-text);
            backdrop-filter: unset;
            padding: 0;
            border-radius: 0;
            height: auto;
            line-height: normal;

            &:hover {
                .ally-vision-hover();
            }

            span {
                color: var(--av-color-text) !important;
                background: none;
            }
        }
    }

    .hp-white-bg {
        padding-top: 0;
        margin-top: 100px;
    }

    .hp-schedule__inner {
        display: flex;
        flex-direction: column;
        gap: 2em;

        .event-preview {
            display: block !important;
        }
    }

    .hp-blog {

        .news-card__link {
            transition: none;

            &:hover {

                .news-card__title {
                    text-decoration: none;
                }
            }
        }
    }

    .hp-program__tickets-2 {
        background: none;
        background-image: none !important;
        border: 0;
        display: inline-block;
        width: auto;

        &:hover {
            border: 0;
            background: none;
        }

        .btn {
            border: 1px solid var(--av-color-border-button);
            background: none;
            color: var(--av-color-text);
            font-size: var(--av-font-size);
            height: auto;
            line-height: normal;
            padding: 0.5em 1em;
            margin: 0 0 1em 0;

            &:hover {
                .ally-vision-hover();
            }

            &:before {
                content: none;
            }

            span {
                color: var(--av-color-text);
            }

            svg {
                display: none;
            }
        }
    }

    .hp-poster {
        aspect-ratio: unset;
        background: none;
        color: var(--av-color-text);
        text-align: left;
        border-radius: 0;
        overflow: visible;
        margin: 2em 0;

        @media (max-width: @screen-s) {
            margin: 0;
        }
    }

    .hp-poster__img {
        display: none;
    }

    .hp-poster__inner {
        width: 100%;
        max-width: 100%;
    }

    .hp-poster__title {
        margin: 0;
    }

    .hp-poster__text {
        font-size: var(--av-font-size);
        margin: 1em 0;
    }

    .hp-poster__btns {
        justify-content: flex-start;
        margin-top: 2em;

        .btn,
        .btn-border {
            background: none;
            font-size: var(--av-font-size);
            text-decoration: none;
            color: var(--av-color-text);
            border: 1px solid var(--av-color-text);

            &:hover {
                .ally-vision-hover();
            }

            &:before,
            &:after {
                content: none;
            }
        }
    }
}
