html.a11y-vision {

    .page-podcasts {

        .event-preview__hover-link {
            display: block;
            border: 0;
            background: none;
        }

        .event-preview:has(.event-preview__hover-link:hover) {
            .ally-vision-hover();
            border-width: 2px;

            .event-preview__title {
                text-decoration: none;
            }
        }

        .top-row {
            margin-bottom: 2em;
            
            a {
                margin-top: 1em;
                font-size: var(--av-font-size);
            }
        }

        .event-preview__title {
            padding: 0;
            text-decoration: underline;
            text-decoration-color: var(--av-color-border);
        }
    }

    .podcasts-recent {
        margin-bottom: 4em;
    }
    
    .podcast-recent {
        border-radius: 0;
        background: none;
        padding: 1.2em;
        border-radius: 1em;
        border: 2px solid var(--av-color-border);
        box-sizing: border-box;
        grid-template-columns: 1fr;
        grid-template-areas: unset;
        text-decoration: none;
        transition: none;

        &:hover {
            .ally-vision-hover();
            border-width: 2px;

            .podcast-recent__title {
                text-decoration: none;
            }
        }

        @media (max-width: @screen-s) {
            padding: 0.8em;
        }
    }

    .podcast-recent__img {
        display: none;
    }

    .podcast-recent__content {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .podcast-recent__title {
        font-weight: bold;
        font-size: 1.7em;
        text-decoration: underline;
        text-decoration-color: var(--av-color-border);

        @media (max-width: @screen-s) {
            font-size: 1.3em;
        }
    }

    .podcast-recent__episode {
        font-size: var(--av-font-size);
    }

    .podcast-recent__date {
        margin-top: 0.5em;
    }

    .podcasts-link-all {
        text-align: left;

        a {
            font-size: var(--av-font-size);
            padding: 0;
        }
    }
}
