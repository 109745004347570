html.a11y-vision {

    .press__years,
    .press__months {
        margin-bottom: 2em;
        overflow: visible;

        .overfolow-x__scroll {
            overflow: visible;
        }

        .overfolow-x__size {
            flex-wrap: wrap;
            gap: 0.5em;
            overflow: visible;

            label {
                border: 1px solid var(--av-color-border-button);
                text-decoration: none;
                overflow: visible;

                &:hover {
                    .ally-vision-hover();
                }

                &:before {
                    background: var(--av-color-text);
                    border-radius: inherit;
                }

                &:has(input:checked) {
                    background: var(--av-color-text);
                }

                &:has(input:focus) {
                    .focused();
                }
            }
        }
    }

    .press-item {
        padding: 1.2em;
        border-radius: 1em;
        border: 2px solid var(--av-color-border);
        box-sizing: border-box;
        grid-template-columns: 1fr;
        grid-template-areas: unset;

        @media (max-width: @screen-s) {
            padding: 0.8em;
        }
    }

    .press-item__type {
        color: var(--av-color-text);
    }

    .press-item__date,
    .press-item__info,
    .press-item__link-wrap  {
        grid-area: unset;
        font-size: var(--av-font-size);
        color: var(--av-color-text);
    }

    .press-item__name {
        font-size: var(--av-font-size);
    }

    .press-item__link {
        font-size: 1.2em;
        font-weight: bold;
    }
}
