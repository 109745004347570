html.a11y-vision {

    .inner {
        width: min(1100px, 100%);
        margin-left: auto;
        margin-right: auto;
    }

    .wrap-page {
        padding-top: 0 !important;
    }

    .white-block {
        width: 100%;
        left: 0;
        margin-left: 0;
        transform: none;
    }

    .black-block {
        background: none;
        color: var(--av-color-text);
    }

    .inner > .white-block {
        padding-left: 0;
        padding-right: 0;
    }

    .content-inner {
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: 1fr;

        .text {
            max-width: 100%;
        }
    }

    .content-inner__sidebar {
        flex-wrap: wrap;
        width: 100%;
    }

    .sidebar-card {
        border: 2px solid var(--av-color-text);
        border-radius: 1em !important;
        min-width: 100%;
        background: none;

        svg {
            display: none;
        }

        ._old-price {
            color: currentColor;
        }

        .block_descr {
            white-space: normal !important;

            p a {
                word-wrap: break-word;
                overflow-wrap: break-word;
                white-space: normal;
                max-width: 100%;
                display: inline-block;
            }
        }
    }

    .sidebar-card__sale,
    .sidebar-cards__pagi {
        display: none;
    }

    .sidebar-card__head {
        + h3,
        + .h3 {
            font-size: 1em;
            font-weight: bold;
        }
    }

    .paginator {
        align-items: center;
    }

    .paginator__link {
        font-size: var(--av-font-size);
        text-decoration: none;

        &._active {
            background: var(--av-color-text);
            color: var(--av-color-bg) !important;
        }
    }

    .paginator__prev,
    .paginator__next {
        transform: scale(1);
    }
}
