html.a11y-vision {

    .page-quiz {
        color: var(--av-color-text);
        background: var(--av-color-bg);
    }

    .quiz {
        color: var(--av-color-text);

        .breadcrumbs {

        }
    }

    .quiz__bg {
        display: none;
    }

    .quiz-start {
        background: none;
        color: var(--av-color-text) !important;
        border: 1px solid var(--av-color-border-button);
        font-size: var(--av-font-size);
        height: auto;
        line-height: normal;
        padding: 0.5em 1em;
        transition: none;

        &:before {
            content: none;
        }

        svg {
            display: none;
        }

        &:hover {
            .ally-vision-hover();
        }
    }

    .quiz__answers-text {
        font-size: var(--av-font-size);
    }

    .quiz__answer-text {
        background: none;
        color: var(--av-color-text);
        backdrop-filter: none;
        border: 1px solid var(--av-color-text);
        box-shadow: none;

        &:hover {
            .ally-vision-hover();
        }

        &:before {
            border-color: currentColor;
        }
    }

    .quiz__quest-result-body {
        color: var(--av-color-text);
        background: none;
        border: 1px solid var(--av-color-border);

        h2 {

            color {
                background: none !important;
                color: var(--av-color-text) !important;
            }
        }
    }

    .quiz__swiper-pagi {

        .swiper-pagination-bullet {
            border-color: var(--av-color-text);
        }

        .swiper-pagination-bullet-active {
            background: var(--av-color-text) !important;
        }
    }
}
