html.a11y-vision {

    .p404__ray,
    .p404__bg-1,
    .p404__bg-2 {
        display: none;
    }

    .p404__desc {
        transform: none;
        left: 0;
        position: relative;
        font-size: var(--av-font-size);
    }

    .p404__go-home {
        background: none;
        border: 1px solid var(--av-color-border-button);
        text-decoration: none;

        &:hover {
            .ally-vision-hover();
        }
    }
}
