.faq {
    margin-bottom: 16px;
    padding: 30px;
    background: #fff;
    @media (max-width: 500px) {
        padding: 30px 20px;
    }
    &__body {
        display: none;
    }
    &__top {
    }
    &__title {
        cursor: pointer;
        margin: 0;
        color: #878787;
        transition: color 0.2s ease;
        .faq._open & {
            color: var(--link);
        }
        html.desktop &:hover {
            color: var(--hover-red);
        }
    }
    &__head {
        cursor: pointer;
        padding-right: 50px;
        position: relative;
        &::after {
            width: 32px;
            height: 32px;
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.33398 14.6654L14.6673 1.33203M14.6673 1.33203V14.132M14.6673 1.33203H1.86732' stroke='url(%23paint0_linear_1636_32379)' stroke-width='1.4'/%3E%3Cpath d='M14.6672 14.132V1.33203H1.86719' stroke='url(%23paint1_linear_1636_32379)' stroke-width='1.4'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1636_32379' x1='-0.491346' y1='5.71203' x2='16.5058' y2='6.74859' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FB8129'/%3E%3Cstop offset='1' stop-color='%23CD380E'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_1636_32379' x1='0.11487' y1='5.53683' x2='16.4322' y2='6.53193' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FB8129'/%3E%3Cstop offset='1' stop-color='%23CD380E'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.3s;
            .faq._open & {
                transform: rotate(90deg);
            }
        }
    }
}
