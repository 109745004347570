.personal-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px 22px;
    margin-top: 60px;
    margin-bottom: 60px;
    @media (max-width: 1600px) {
        gap: 40px 16px;
    }
    @media (max-width: 992px) {
        gap: 40px 12px;
    }
    @media (max-width: 890px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
}
.persona {
    position: relative;
    overflow: hidden;
    &__img-wrap {
        aspect-ratio: 604/540;
        position: relative;
        margin-bottom: 20px;
        padding: 30px;
        display: flex;
        align-items: flex-end;
        @media (max-width: 992px) {
            padding: 20px;
        }
        @media (max-width: 600px) {
            aspect-ratio: 351/258;
        }
    }

    &__tags {
        position: relative;
        z-index: 33;
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
        max-width: 420px;
        li {
            box-shadow: inset 0 0 0 2px #fff;
            border-radius: 50px;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            padding: 8px 12px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: -0.12px;
            backdrop-filter: blur(10px);
            @media (max-width: 1024px) {
                font-size: 12px;
                padding: 6px 12px;
            }
            &._bg {
                box-shadow: none;
                backdrop-filter: none;
                background: linear-gradient(
                    71deg,
                    #7849a9 3.41%,
                    #6e7ed6 99.53%
                );
            }
        }
    }
    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 1s;
        .persona:has(.persona__more):hover & {
            transform: scale(1.02);
        }
    }

    &__name {
        font-family: Unbounded;
        .clampf(20,24);
        margin-bottom: 12px;
        font-style: normal;
        font-weight: 500;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    &__date {
        padding-left: 28px;
        position: relative;
        margin-bottom: 16px;
        background-repeat: no-repeat;
        background-position: left center;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.16px;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 3.85714V9L12.4286 12.4286M9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9C17 13.4183 13.4183 17 9 17Z' stroke='url(%23paint0_linear_1546_25222)' stroke-width='1.4'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1546_25222' x1='-1.1904' y1='6.25599' x2='19.2062' y2='7.49987' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FB8129'/%3E%3Cstop offset='1' stop-color='%23CD380E'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    }
    &__more {
        span::after {
            width: 100vmax;
            height: 100vmax;
            display: block;
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    &__desc {
        font-size: 16px;
        p {
            font-size: inherit;
            margin: 0.8em 0;

            &:last-child {
                // margin-bottom: 0;
            }
        }
    }
}
