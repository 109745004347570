.news-day {
    padding: 16px;
    color: #fff;
    background: linear-gradient(71deg, #7849a9 3.41%, #6e7ed6 99.53%);
    margin-bottom: 22px;
    margin-top: 100px;

    @media (max-width: 1024px) {
        margin-bottom: 12px;
        padding: 12px;
    }
    &__left {
        padding-left: 14px;
        display: flex;
        padding-right: 14px;
        padding-bottom: 40px;
        flex-direction: column;

        @media (max-width: 1024px) {
            padding-bottom: 30px;
        }

        @media (max-width: 700px) {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 20px;
        }
    }
    &__row {
        display: grid;
        gap: 20px;
        grid-template-columns: auto 40%;
        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
    }
    &__img-wrap {
        padding-bottom: 77.6%;
        position: relative;
        border-radius: 36px !important;
        overflow: hidden;

        @media (max-width: 992px) {
            border-radius: 22px !important;
        }

        @media (max-width: 500px) {
            border-radius: 11px !important;
        }
    }
    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0 !important;
    }
    &__content {
        .h1,
        h1 {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .h2,
        h2 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    &__icon {
        width: 60px;
        margin-top: 24px;
        margin-bottom: 20px;

        @media (max-width: 700px) {
            width: 50px;
        }
    }
    &__links {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
}

.glossarii-preview {
    padding: 30px;
    padding-top: 40px;
    aspect-ratio: 112/125;
    color: #fff;
    display: flex;
    flex-direction: column;
    background: linear-gradient(71deg, #7849a9 3.41%, #6e7ed6 99.53%);

    @media (max-width: 600px) {
        aspect-ratio: auto;
        min-height: 370px;
    }

    &__title.h1 {
        margin-top: 20px;
        margin-bottom: 0;
        font-size: 30px;

        @media (max-width: 1400px) {
            font-size: 24px;
        }

        @media (max-width: 1024px) {
            margin-top: 0;
        }

        @media (max-width: 600px) {
            margin-top: 20px;
        }
    }
    &__desc {
        @media (max-width: 600px) {
            margin-bottom: 10px;
        }
        
        p {
            font-size: 16px;
        }
    }
    &__icon {
        width: 60px;

        @media (max-width: 1024px) {
            display: none;
        }

        @media (max-width: 600px) {
            display: block;
        }
    }
    &__bottom {
        margin-top: auto;
    }
    &__link {
        span::after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 100vmax;
            height: 100vmax;
            display: block;
            content: "";
        }
    }
}
