.hp-schedule {
    .h1{
        max-width: 100%;
    }
    &+.hp-schedule{
        margin-top: 70px;
    }
    .event-preview__info {
        display: flex;
    }

    .event-preview__top {
        // transform: translateY(5%);
        transition: all 0.6s ease;
    }

    .event-preview__img {
        transform: scale(1.1);
        transition: transform 6s ease-out;
    }

    .event-preview {
        opacity: 0;
        transition: all 0.6s ease;

        &._is-animated {
            opacity: 1;

            .event-preview__top {
                transform: translateY(0);
            }

            .event-preview__img {
                transform: scale(1);
            }
        }
    }

    @media (min-width: @screen-hp-large) {

        .hp-schedule__inner {
            grid-gap: 45px 22px;
        }

        .hp-shedule__item {

            &:nth-child(1) {

                .event-preview {
                    grid-gap: 5px 22px;
                }

                .event-preview__middle {
                    grid-column: span 3;
                }

                .event-preview__bottom-wrap {
                    grid-column: span 3;
                }
            }
        }
    }

    @media (max-width: @screen-s) {
        .event-preview__top {
            aspect-ratio: 1.46;
        }
    }
}

.hp-schedule__inner {
    display: grid;
    margin-top: 40px;
    display: grid;
    grid-gap: 45px 16px;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: @screen-s) {
        grid-gap: 40px;
        grid-template-columns: 1fr;
    }
}

.hp-shedule__item {

    &:nth-child(2) {
        .event-preview,
        .event-preview__img {
            transition-delay: 0.2s;
        }
    }

    &:nth-child(3) {
        .event-preview,
        .event-preview__img {
            transition-delay: 0.4s;
        }
    }

    @media (min-width: 701px) {

        &:nth-child(1) {
            grid-column: span 3;

            .event-preview__top {
                aspect-ratio: 1.8;
                grid-column: span 6;
                padding: 50px;
            }

            .event-preview__awards {
                display: flex;
            }

            .event-preview__award {
                width: 150px;
                height: 108px;
            }

            .event-preview__award-top {
                font-size: 7.5px;
            }

            .event-preview__award-name {
                font-size: 8.8px;
            }

            .event-preview__award-year {
                font-size: 12px;
            }
        }

        &:nth-child(2) {

        }
    }

    @media (min-width: 1025px) {
        &:nth-child(1) {
            grid-column: span 3;

            .event-preview {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-gap: 5px 16px;
            }

            .event-preview__top {
                aspect-ratio: 2.74;
                grid-column: span 6;
                padding: 50px;
            }

            .event-preview__middle {
                grid-column: span 2;
            }

            .event-preview__bottom-wrap {
                grid-column: span 4;

                > :nth-child(1) {
                    margin-top: 26px;
                }
            }
        }

        &:nth-child(2) {
            grid-column: span 2;

            .event-preview__top {
                aspect-ratio: 1.82;
            }
        }
    }
}


.hp-poster {
    width: 100%;
    grid-column: span 3;
    aspect-ratio: 2.3;
    background-color: var(--dark);
    color: #fff;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    opacity: 0;
    transition: all 0.6s ease;

    @media (max-width: 1024px) {
        aspect-ratio: 1.12;
        grid-column: span 2;
    }

    @media (max-width: 992px) {
        border-radius: 30px;
    }

    @media (max-width: @screen-s) {
        aspect-ratio: 1;
        grid-column: unset;
    }

    &._is-animated {
        opacity: 1;
    }
}

.hp-poster__img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    transform: scale(1.1);
    transition: transform 6s ease-out;

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.4;
    }

    .hp-poster._is-animated & {
        transform: scale(1);
    }
}

.hp-poster__inner {
    position: relative;
    max-width: 80%;
    z-index: 2;
    margin: 0 auto;

    @media (max-width: @screen-l) {
        max-width: 70%;
    }

    @media (max-width: @screen-s) {
        max-width: 94%;
    }
}

.hp-poster__title {
    margin-top: 24px;

    @media (max-width: @screen-s) {
        margin-top: 0;
    }
}

.hp-poster__text {
    margin: 10px 0;
    font-size: 18px;
    line-height: 1.33;

    @media (max-width: @screen-m) {
        font-size: 16px;
    }
}

.hp-poster__btns {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;

    .btn, .btn-border {
        margin: 0;
        height: 50px;
    }

    .btn-border {

        &:before {
            background: #fff;
        }
    }
}
