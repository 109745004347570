html.a11y-vision {

    .shedule-top {
        padding-left: 0;
        padding-right: 0;

        @media (max-width: 1024px) {
            padding-left: var(--inner-padding);
            padding-right: var(--inner-padding);
        }
    }

    .shedule-top__body {
        border-top: 1px solid var(--av-color-border);
    }
    
    .shedule-top__legend,
    .shedule-top__day-load {
        display: none;
    }

    .shedule-top__month {
        margin-top: 2em;
        display: none;
    }

    .shedule-top__today {
        display: block !important;
        text-align: left;
    }

    .shedule-top__days {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 1em 3em;
        margin-left: 0;
        margin-right: 0;
        padding: 0 0.7em;
    }

    .shedule-top__date {
        font-size: 1.5em;

        @media (max-width: @screen-s) {
            font-size: 1.5em;
        }
    }

    .shedule-top__now-day {
        font-size: var(--av-font-size);
    }

    .shedule-top__day {
        padding-bottom: 1em;

        &._active {

            .shedule-top__day-number {
                background: var(--av-color-text);
                color: var(--av-color-bg) !important;
            }
        }
    }

    .shedule-top__weekend {
        margin-top: 1em;
        color: var(--av-color-text);
        font-size: 0.8em;
    }

    .shedule-top__day-name {
        font-size: var(--av-font-size);
    }

    .shedule-top__day-number {
        box-sizing: border-box;
        border: 1px solid var(--av-color-border-button);
        font-size: var(--av-font-size);
        width: 2.5em;
        height: 2.5em;
        text-decoration: none;

        &:hover {
            color: var(--av-color-text) !important;
            text-decoration: none !important;
            background: rgba(0,0,0,0.07);
            border: 1px dashed currentColor;
        }
    }

    ._day-off {

        .shedule-top__day-number {
            border-color: var(--av-color-border);
        }
    }

    .shedule-head {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        font-size: var(--av-font-size);
        gap: 4em;

        .filter-popup__block {
            width: auto;

            @media (max-width: 1024px) {
                width: 100%;
            }
        }

        .filter-popup__columns {
            gap: 0.5em 1em;
        }

        .ss-main {
            padding: var(--ss-spacing-s);
            border-radius: var(--ss-border-radius);
        }
    }

    .shedule-files {
        flex-direction: column;
        gap: 0.5em;

        a {
            font-size: var(--av-font-size);
            display: inline-flex;

            svg {
                display: none;
            }

            span {

                &:before {
                    content: 'Скачать ';
                }
            }
        }
    }

    .filter-menu-open {

        svg {
            display: none;
        }
    }

    .filter-popup__sbmt {
        border: 1px solid var(--av-color-border-button);
        background: var(--av-color-bg);
        color: var(--av-color-text);
        font-size: var(--av-font-size);
    
        &:hover {
            .ally-vision-hover();
        }
    }
    
    .filter-popup__head {
        gap: 10px;
    }
    
    .filter-popup__title {
        margin: 0 !important;
    }
    
    .filter-popup__close {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        border: 1px solid currentColor;
        border-radius: 50%;
        box-sizing: border-box;
    
        &:hover {
            .ally-vision-hover();
        }
    
        svg {
            width: 140%;
            height: 140%;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -70% 0 0 -70%;
        }
    }
    
}
