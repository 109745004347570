html.a11y-vision {

    .podcast-big {
        height: auto;
        padding: 0;
        border-radius: 0;
        overflow: visible;
        margin: 3em 0;
    }

    .podcast-big__btns {
        text-align: left;
        margin-left: 0;
        margin-right: 0;

        .btn-border__content {
            color: var(--av-color-text);
        }
    }

    .podcast-big__bottom {
        text-align: left;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 1em;
    }

    .news-day {
        background: none;
        padding: 1.2em;
        border-radius: 1em;
        border: 2px solid var(--av-color-border);
        box-sizing: border-box;
        color: var(--av-color-text);
        margin: 3em 0;

        @media (max-width: @screen-s) {
            padding: 0.8em;
        }

        .news-day__img-wrap {
            display: none;
        }

        .h1 {
            margin: 0.5em 0;
        }
    }

    .news-day__icon {
        display: none;
    }

    .news-day__row {
        grid-template-columns: 1fr;
    }

    .news-day__left {
        padding: 0;
    }

    .news-day__content {

        > :first-child {
            margin-top: 0;
        }
    }

    .news-day__links {

        .btn-border__content {
            color: var(--av-color-text);
        }
    }

    .glossarii-preview {
        padding: 1.2em;
        border-radius: 1em;
        border: 2px solid var(--av-color-border);
        box-sizing: border-box;
        color: var(--av-color-text);
        margin: 3em 0;
        background: none;
        aspect-ratio: unset;

        @media (max-width: @screen-s) {
            padding: 0.8em;
        }
    }

    .glossarii-preview__icon {
        display: none;
    }

    .glossarii-preview__title {
        margin-top: 0;
        margin-bottom: 0.5em;
    }

    .btn-border__content {
        color: var(--av-color-text) !important;

        &:after {
            content: none;
        }
    }

    .blog-person {
        width: 100%;
        max-width: 100%;
    }

    .blog-person__img {
        display: none;
    }

    .blog-date {
        font-size: var(--av-font-size);
        font-weight: bold;
    }
}
