.notifications {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notification {
    position: relative;
    background: var(--dark);
    color: #fff;
    z-index: 101;
    padding: 13px 0;
    width: 100%;
    border-bottom: 1px dashed rgba(255,255,255,0.1);

    &._is-hidden {
        display: none;
    }

    &:last-child {
        border: 0;
    }
}

.notification__inner {
    background: transparent !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;

    @media (max-width: @screen-m) {
        gap: 10px;
    }

    @media (max-width: @screen-s) {
        gap: 5px;
    }
}

.notification__text,
.notification-cookie__text {
    width: 100%;
    font-size: 14px;
    font-family: Circe;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: @screen-m) {
        font-size: 12px;
    }

    b {
        color: #e65f1d;
    }

    a {
        text-decoration: underline;

        html.desktop &:hover {
            text-decoration-color: transparent;
        }
    }

    p {
        font-size: inherit;
        margin: 0;
    }
}

.notification__btn-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 40px;
    max-height: 100%;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    background: none;
    border: 0;
    color: #fff;
    padding: 0;
    margin: -12px -12px -12px 0;
    transition: color 0.2s ease;

    html.desktop &:hover {
        color: var(--link);
    }

    svg {
        display: block;
        width: 24px;
        height: 24px;
    }

    span {
        .v-hidden();
    }
}

.notification-cookie {
    background: #FB8129;
    box-shadow: 0px 6px 10px 0px rgba(214, 54, 11, 0.40);
    border-radius: 25px;
    margin-bottom: 30px;
    color: #fff;
    max-width: calc(100% - (var(--inner-padding)) * 2);
    padding: 10px 0;

    &._is-hidden {
        display: none;
    }

    @media (max-width: @screen-m) {
        margin-bottom: 20px;
    }
}

.notification-cookie__inner {
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    @media (max-width: @screen-m) {
        gap: 10px;
    }
    
    @media (max-width: @screen-s) {
        gap: 5px;
        padding: 0 20px 0 12px;
    }

    b {
        color: currentColor;
    }
}

.notification-cookie__btn-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 40px;
    max-height: 100%;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    background: none;
    border: 0;
    color: #fff;
    padding: 0;
    margin: -12px -12px -12px 0;
    transition: all 0.2s ease;
    text-align: center;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 700;
    font-family: Circe;
    user-select: none;

    html.desktop &:hover {
        text-decoration-color: transparent;
    }
}
