.a11y-vision-menu {
    display: none;
    margin-top: 0.5em;
    margin-bottom: 3em;

    html.a11y-vision & {
        display: block;
    }
}

.a11y-vision-menu__inner {
    margin-top: 1em;
    padding-bottom: 2em;
    border-bottom: 2px solid var(--av-color-text);
}

.a11y-vision-menu__main-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    gap: 0.5em 1em;

    li._is-active {

        > a {
            background: #000;
            color: #fff !important;
            text-decoration: none;

            &:hover {
                color: var(--av-color-text) !important;
                background: rgba(0,0,0,0.07);
            }
        }
    }

    a {
        display: inline-block;
        font-weight: bold;
        padding: 0 0.2em;
    }

    + .a11y-vision-menu__main-list {
        margin-top: 1.5em;
    }
}

.a11y-vision-menu__full-menu {
    margin: 1em 0 0 0;
    padding: 1.5em 1em;
    border: 2px solid var(--av-color-text);
    border-radius: 0.5em;
    display: none;

    @media (max-width: 1024px) {
        display: none !important;
    }

    @media (max-width: @screen-s) {
        padding: 1em 0.5em;
    }

    .a11y-vision-button {
        margin-top: 2em;
    }
}

.a11y-vision-menu__full-menu-list {
    display: grid;
    list-style: none;
    margin: 0;
    padding: 0;
    grid-template-columns: 1fr;
    gap: 2em 3em;

    > li {

        > a {
            display: inline-block;
            font-weight: bold;
            // text-decoration: none !important;
            font-size: 1.2em;
            padding: 0 0.2em;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            gap: 0.5em 2em;
            margin: 1em 0 0 0;
            padding: 0;

            @media (max-width: @screen-s) {
                flex-direction: column;
                gap: 1em;
            }

            a {
                display: inline-block;
                // text-decoration: none !important;
                padding: 0 0.2em;
            }

            li._is-active {

                > a {
                    background: #000;
                    color: #fff !important;

                    &:hover {
                        color: var(--av-color-text) !important;
                        background: rgba(0,0,0,0.07);
                    }
                }
            }
        }
    }
}

.a11y-vision-button.a11y-vision-menu-btn-desktop {

    @media (max-width: 1024px) {
        display: none;
    }
}

.a11y-vision-button.a11y-vision-menu-btn-mobile {
    display: none;

    @media (max-width: 1024px) {
        display: inline-flex;
    }
}
