html.a11y-vision {
   
    .footer {
        background: var(--av-color-bg);
        border-radius: 0;
        color: var(--av-color-text);
        font-size: var(--av-font-size);
        line-height: 1.2;

        * {
            font-size: var(--av-font-size);
            line-height: 1.2;
        }

        a {
            font-weight: bold;
        }

        > .inner {
            border-top: 2px solid var(--av-color-text);
            padding-top: 2em;
        }
    }

    .footer__top {
        display: block;
    }

    .footer__logo {
        display: none;
    }
    
    .footer__info {
        width: 100%;
        text-align: left;

        p {
            font-size: var(--av-font-size);
        }
    }

    .footer__right {
        display: none;
    }

    .footer__menus {
        width: 100%;
        margin-top: 1em;
        margin-bottom: 3em;
    }

    .footer__menu {
        width: 30%;

        ul {
            gap: 0.4em;
        }
    }

    .footer__bottom {
        // border-top: 2px solid var(--av-color-border);
        border: 0;

        @media (max-width: 999px) {
            margin-top: 1em;
        }
    }

    .footer__bottom-row {
        display: block;
    }

    .footer__copy {
        width: 100%;
        border-bottom: 0;
        text-align: left;
        margin-bottom: 0.5em;

        span {
            color: var(--av-color-text);
        }
    }

    .footer__ctm {

        svg {
            display: none;
        }

        span {
            display: inline;
        }
    }

    .footer__links {
        position: relative;
        left: 0;
        transform: none;
        margin-bottom: 0.5em;
    }
}
