html.a11y-vision {

    .breadcrumbs {
        overflow: visible;
        user-select: auto;
        // border: 1px solid #000;
        // padding: 0.5em 0;
        // border-radius: 0.7em;
        margin-bottom: 2em;
        flex-wrap: wrap;
        padding: 0 var(--inner-padding);

        &:before,
        &:after {
            content: none;
        }

        li {
            font-size: var(--av-font-size);
            color: currentColor;
            white-space: normal;

            &:after {
                color: currentColor;
            }
        }

        a {
            font-weight: bold;
        }
    }
}
