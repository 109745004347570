html.a11y-vision {

    .top-block {

        &:has(.top-block__img) {
            display: none;
        }
    }

    .gallery-slider {
        display: none;
    }
}
