.hp-video {
    background: #000;
    border-radius: 50px;
    position: relative;
    z-index: 2;
    height: 0;
    padding-top: 56%;
    overflow: hidden;
    margin-top: calc(100vh - 160px);

    @media (min-width: @screen-fix) and (min-height: 1080px) {
        margin-top: 1100px;
    }

    @media (max-width: @screen-l) {
        padding: 0;
        height: 700px;
        border-radius: 30px;
        margin-top: calc(100vh - 87px);
    }

    @media (max-width: @screen-s) {
        height: auto;
        width: 100%;
        aspect-ratio: 4 / 3;
    }

    @media (max-width: 399px) {
        aspect-ratio: 1;
    }
}

.hp-video__inner {
    position: absolute;
    left: 50px;
    right: 50px;
    bottom: 60px;
    z-index: 2;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (min-width: @screen-hp-large) {
        left: 60px;
        right: 60px;
    }

    @media (max-width: @screen-l) {
        flex-direction: column;
        align-items: center;
        left: 40px;
        right: 40px;
        bottom: 40px;
    }

    @media (max-width: @screen-s) {
        left: 20px;
        right: 20px;
        bottom: 30px;
    }
}

.hp-video__title {
    margin: 0;
    font-size: 46px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.46px;

    @media (max-width: @screen-l) {
        font-size: 24px;
    }
}

.hp-video__details {
    display: inline-block;
    flex-shrink: 0;
    margin-left: 40px;
    height: 50px;
    border-radius: 50px;
    background: transparent;
    box-sizing: border-box;
    padding: 0 22px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.16px;
    position: relative;
    overflow: hidden;
    color: var(--body-bg);
    backdrop-filter: blur(10px);

    @media (max-width: @screen-l) {
        margin-left: 0;
        margin-top: 20px;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid transparent;
        border-radius: inherit;
        z-index: 0;
        background: var(--gradient) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, 
                      linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        z-index: 1;
        background: var(--gradient);
        opacity: 0;
        transition: opacity 0.2s ease;
    }

    span {
        position: relative;
        z-index: 2;
    }

    html.desktop &:hover {
        &:after {
            opacity: 1;
        }
    }
}

.hp-video__play {
    display: block;
    width: 124px;
    height: 124px;
    box-sizing: border-box;
    border: 1px solid rgba(166, 166, 166, 0.3);
    background: transparent url('../../../static/img/icon-play.svg') no-repeat center center;
    border-radius: 50%;
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    margin: -62px 0 0 -62px;
    transition: all 0.3s;

    @media (max-width: @screen-s) {
        width: 88px;
        height: 88px;
        margin: -94px 0 0 -44px;
    }

    @media (max-width: 399px) {
        
    }

    html.desktop &:hover {
        border-color: rgba(166, 166, 166, 1);
        &:before {
            opacity: 0;
            animation: playPulse 1.4s ease-in-out infinite;
        }

        &:after {
            opacity: 0;
            animation: playPulse 1.4s ease-in-out infinite 0.5s;
        }
    }

    &:before,
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        will-change: transform;
        box-sizing: border-box;
        border: 1px solid rgba(166, 166, 166, 0.5);
        border-radius: 50%;
        pointer-events: none;
        opacity: 0;
    }

    &:before {
        transform: scale(1.3);

        @media (max-width: @screen-s) {
            width: 114px;
            height: 114px;
        }
    }

    &:after {
        transform: scale(1.56);

        @media (max-width: @screen-s) {
            width: 138px;
            height: 138px;
        }
    }

    span {
        color: transparent;
    }
}

@keyframes playPulse {
    0% {
        opacity: 0;
        transform: scale(1);
    }

    10% {
        opacity: 1;
    }

    65%, 100% {
        opacity: 0;
        transform: scale(1.56);
    }
}

.hp-video__video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}
