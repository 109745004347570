html.a11y-vision {

    .top-row {
        display: block;
    }

    .top-row__filters {
        margin-top: 1em;
        margin-bottom: 3em;
        gap: 0.5em;
        flex-wrap: wrap;

        a {
            text-decoration: none;
        }

        .overfolow-x__size {
            flex-wrap: wrap;
            gap: 0.5em; 

            @media (max-width: @screen-s) {
                
                a {
                    white-space: normal;
                }
            }
        }
    }

    .events-grid {
        grid-template-columns: 1fr;
        gap: 2em;
    }

    .event-preview {
        padding: 1.2em;
        border-radius: 1em;
        border: 2px solid var(--av-color-border);
        box-sizing: border-box;

        @media (max-width: @screen-s) {
            padding: 0.8em;
        }

        .btn-link {
            background: none;
            font-size: var(--av-font-size);

            &:hover {
                .ally-vision-hover();
            }
        }
    }

    .event-preview__top {
        display: none;
    }

    .event-preview__info {

        li {
            font-size: var(--av-font-size);
        }

        [class^="icon-"] {
            height: 1.5em;

            svg {
                height: 100%;
                width: auto;
            }
        }
    }

    .event-preview__hover-link {
        display: none;
    }

    .event-preview__title {
        margin-top: 0;
        background: none;
        color: currentColor;
        -webkit-text-fill-color: var(--av-color-text) !important;

        @media (max-width: @screen-s) {
            font-size: 1.3em;
        }
    }
}
