html.a11y-vision {

    body:has(.legend-page) {

        .breadcrumbs {
            margin-bottom: 0;
        }
    }

    .legend-page {
        grid-template-columns: 1fr;

        ~ .white-block {
            
            .h1 {
                margin-top: 0;
            }
        }
    }

    .legend-page__content {

        .d-max-1024 {
            display: block !important;

            img {
                max-width: 600px;
            }
        }
    }

    .legend-page__sidebar {

        .legend-page__img {
            display: none !important;
        }
    }

    .legend-page__sidebar-fixed {
        position: relative;
        top: 0;
    }
}
