.shema-page {
    &__legends {
        margin-top: 16px;
        background: #fff;
        padding: 40px 30px;
        list-style: none;
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        gap: 20px;
        @media (max-width: 1200px) {
            grid-template-columns: auto auto auto auto;
        }
        @media (max-width: 830px) {
            grid-template-columns: auto auto auto;
        }
        @media (max-width: 700px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 550px) {
            grid-template-columns: 1fr;
        }
        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            font-weight: 700;
            line-height: 24px; /* 150% */
            letter-spacing: -0.16px;
        }
    }
}
.shema {
    background: #fff;
    .swiper-slide {
        padding: 16px;
        height: auto;
        @media (max-width: 880px) {
            height: inherit;
        }
    }
    &__row {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 20px;
        min-height: calc(100vh - 400px);
        @media (max-width: 1100px) {
            grid-template-columns: auto 400px;
        }
        @media (max-width: 880px) {
            grid-template-columns: 1fr;
        }
    }
    &__svg-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        svg {
            max-width: 673.83px;
            @media (max-width: 880px) {
                height: 330px;
                width: 673px;
                max-width: 100%;
                object-fit: cover;
            }
            path,
            rect,
            circle {
                &:not([data-room]) {
                    pointer-events: none;
                }
            }
            [data-room] {
                transition: opacity 0.3s;
                opacity: 0;
                -webkit-tap-highlight-color: transparent;
                &:hover {
                    opacity: 1 !important;
                }
                &._active {
                    opacity: 1;
                }
            }
        }
    }
    &__svg-legends {
        background: #e7e4e5;
        padding: 40px;
        @media (max-width: 600px) {
            padding: 30px 16px;
        }
        .h2,
        h2 {
            margin-top: auto;
        }
    }
    &__svg-legend {
        font-size: 16px;
        margin-bottom: 20px;
        transition: color 0.3s ease;
        
        &._active,
        &:hover {
            color: var(--link);
        }

        p {
            margin: 5px 0;
            font-size: inherit;
        }
    }

    &__svg-legend-name {
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.16px;
        // transition: all 0.3s ease;
    }

    a&__svg-legend-name {
        cursor: pointer;
        text-decoration: underline;
        transition: text-decoration-color 0.3s ease;

        &:hover {
            text-decoration-color: transparent;
        }
    }
}
