.header {
    --color: #0f0f0f;
    --line: #cfcfcf;
    --anim-fun: ease-in-out;
    --anim-dur: 0.3s;
    --anim-delay: 0.3s;
    color: var(--color);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    transition: top 0.3s;

    html._scroll-down & {
        @media (min-width: 1200px) {
            top: -100px;
        }
    }
    .inner {
        background: #e7e4e5;
        transition: background 0.3s;
        position: relative;
        z-index: 3;

        @media (min-width: @screen-fix) {
            width: auto;
        }
    }
    &__overlay {
        z-index: 1;
        width: 100vw;
        position: absolute;
        pointer-events: none;
        height: 120vh;
        opacity: 0;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(50px);
        transition: opacity var(--anim-dur) var(--anim-fun) calc(var(--anim-dur) + var(--anim-delay));
        
        header._overlay-visible &,
        body._burger-menu-open & {
            opacity: 1;
            pointer-events: auto;
            transition: opacity var(--anim-dur) var(--anim-fun);

            @media (min-width: 1201px) {
                transition-delay: var(--anim-delay);
            }
        }
    }
    &__top {
        padding-top: 26px;
        padding-bottom: 26px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        border-bottom: 1px solid var(--line);

        @media (min-width: @screen-fix) {
            width: 1900px;
            margin-left: auto;
            margin-right: auto;
            border-bottom-color: transparent;

            &:after {
                content: '';
                display: block;
                width: 100vw;
                position: absolute;
                bottom: -1px;
                left: 50%;
                margin-left: -50vw;
                border-bottom: 1px solid var(--line);
            }
        }
    }
    &__lang {
        &-current {
            background: transparent;
            padding: 0;
            border: 0 solid;
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            &:hover {
                color: var(--link);
            }
        }
        &-list {
            display: none;
        }
    }

    &__left-menu,
    &__menu {
        padding: 0;
        display: flex;
        flex-direction: row;
        list-style: none;
        gap: 20px;
        margin: 0;
        align-items: center;
        li {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
        }
        a {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            &:hover {
                color: var(--link);
            }

            svg + span {
                display: none;
            }
        }
    }
    &__left-menu {
        margin-right: auto;
    }
    &__btns {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
    &__logo {
        margin-right: auto;
        line-height: 0;
        @media (max-width: 1200px) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        @media (max-width: @screen-s) {
            transition: opacity 0.1s ease;

            ._burger-menu-open & {
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.1s ease;
            }
        }
    }
    &__logo-light {
        display: none;
    }
    &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
    }
    &__sertificat {
        display: flex;
        height: 48px;
        padding: 10px 20px 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 50px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        position: relative;
        overflow: hidden;

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: var(--gradient);
            opacity: 0;
            transition: inherit;
            border-radius: inherit;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            transition: inherit;
            border-radius: inherit;
            border: 1px solid var(--color);
            box-sizing: border-box;
        }

        html.desktop &:hover {
            color: #fff;

            &:before {
                opacity: 1;
            }
        }

        svg {
            position: relative;
            z-index: 3;
        }

        span {
            position: relative;
            z-index: 3;
        }
    }
    &__buy-bilet {
        background: var(--gradient);
        display: flex;
        height: 48px;
        padding: 10px 20px 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        color: #fff;
        border-radius: 50px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        position: relative;
        overflow: hidden;

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: #fff;
            opacity: 0;
            transition: inherit;
        }

        html.desktop &:hover {
            color: var(--link);

            &:before {
                opacity: 1;
            }

            span {
                color: transparent;
                transition: inherit;
            }
        }

        svg {
            position: relative;
            z-index: 2;
        }

        span {
            position: relative;
            z-index: 2;
            .color();
            color: currentColor;
            -webkit-text-fill-color: unset;
        }
    }
    &__burger {
        width: 40px;
        height: 40px;
        border: 0 solid;
        background: transparent;
        position: relative;
        display: none;
        margin: -11px -8px -11px -11px;

        @media (max-width: 1200px) {
            display: block;
        }

        span {
            height: 2px;
            display: block;
            position: absolute;
            left: 50%;
            width: 24px;
            margin-left: -11px;
            background: var(--dark);
            transition: margin 0.3s, opacity 0.3s, transform 0.3s;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: var(--gradient);
                opacity: 0;
                transition: opacity 0.3s ease;
            }

            &:first-child {
                transform: translateY(-7px);
                ._burger-menu-open & {
                    transform: rotate(-45deg);
                }
            }
            &:nth-child(2) {
                ._burger-menu-open & {
                    opacity: 0;
                }
            }
            &:last-child {
                transform: translateY(7px);
                ._burger-menu-open & {
                    transform: rotate(45deg);
                }
            }
        }

        html.desktop &:hover {
            span:before {
                opacity: 1;
            }
        }
    }
    .search {
        svg {
            @media (max-width: @screen-l) {
                width: 36px;
                height: 36px;
                margin-top: -6px;
                margin-bottom: -6px;
            }

            @media (max-width: @screen-s) {
                width: 32px;
                height: 32px;
                margin-top: -4px;
                margin-bottom: -4px;
            }
        }
    }
}

.main-menu {
    position: relative;

    @media (min-width: @screen-fix) {
        width: 1900px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 0;
        height: calc(100svh - 77px);
        transition: max-height 0.6s;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        ._burger-menu-open & {
            max-height: calc(100svh - 77px);
        }
    }
    &__sub-back {
        border: 0 solid;
        padding: 0;
        background: transparent;
        font-family: Circe;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
        gap: 10px;
        &::before {
            position: relative;
            top: 1px;
            content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4531 14.25L5.79928 9L11.4531 3.75' stroke='%23878787' stroke-width='1.4'/%3E%3C/svg%3E%0A");
            display: block;
        }
        @media (min-width: 1200px) {
            display: none;
        }
        span {
            .color();
        }
    }
    &__menu {
        display: flex;
        flex-direction: row;
        list-style: none;
        margin: 0;
        padding: 0;
        justify-content: space-between;
        user-select: none;

        @media (max-width: 1200px) {
            flex-direction: column;
            margin-top: 40px;
            margin-bottom: 40px;
            gap: 24px;
            body:has(.main-menu__sub._active) & {
                opacity: 0;
                pointer-events: none;
            }
        }
        a {
            color: var(--color);
            font-size: 14px;
            font-style: normal;
            padding: 15px 0;
            font-weight: 700;
            line-height: 171.429%;
            text-transform: uppercase;
            position: relative;
            display: block;
            @media (max-width: 1200px) {
                padding: 0;
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                line-height: normal;
                &::before {
                    display: none !important;
                }
                &::after {
                    margin-left: 10px;
                    display: inline-block;
                    height: 18px;
                    position: relative;
                    top: -1px;
                    content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.54688 14.25L12.2007 9L6.54688 3.75' stroke='%23878787' stroke-width='1.4'/%3E%3C/svg%3E%0A");
                }
            }
            &::before {
                position: absolute;
                left: 0;
                bottom: 0%;
                height: 2px;
                width: 0;
                background: var(--gradient);
                display: block;
                content: "";
                transition: width 0.3s;
            }
            &._active,
            &:hover {
                background: var(--gradient);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            &._active {
                &::before {
                    width: 100%;
                }
            }
        }
    }
    &__sub-wrapper {
        @media (min-width: 1201px) {
            position: relative;
            z-index: 2;
        }
    }
    &__sub {
        padding: 16px 0;

        @media (min-width: 1201px) {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            box-sizing: border-box;
            visibility: hidden;
            will-change: transform;
            clip-path: polygon(-50vw 0%, 150vw 0%, 150vw 0%, -50vw 0%);
            transition: clip-path var(--anim-dur) var(--anim-fun) calc(var(--anim-dur) + var(--anim-delay)),
                        visibility 0s linear calc((var(--anim-dur) + var(--anim-delay)) * 2);

            &:before {
                content: "";
                display: block;
                width: 100vw;
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: -50vw;
                height: 100%;
                background: #e7e4e5;
                z-index: 0;
            }

            &._active {
                visibility: visible;
                z-index: 2;
                transition: clip-path 0.3s var(--anim-delay);
                clip-path: polygon(-50vw 0%, 150vw 0%, 150vw 100%, -50vw 100%);
            }
        }

        @media (max-width: 1200px) {
            display: none;

            &._active {
                display: block;
                position: absolute;
                padding: 40px 0;
                left: 0;
                top: 0;
                width: 100%;
                display: flex;
                flex-direction: column;
                min-height: 100%;
            }
        }
    }
    &__sub-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        position: relative;
        z-index: 2;
        min-height: 282px;
        @media (max-width: 1200px) {
            grid-template-columns: 1fr;
            gap: 0;
            display: flex;
            flex-direction: column;
            flex: 1;
        }
        ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            flex-direction: column;
            gap: 16px;
            padding-top: 24px;
            padding-bottom: 24px;
            @media (max-width: 1200px) {
                margin-bottom: 16px;

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }
            &.span-2 {
                grid-column: span 2;
            }
            &.span-3 {
                grid-column: span 3;
            }
            a {
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 171.429%;
                text-transform: uppercase;
                transition: color 0.2s ease;

                html.desktop &:hover {
                    color: #e65f1d;
                }
            }
            @media (max-width: 1200px) {
                padding: 0;
            }
            li {
                --anim-dl: 0.075s;
                --anim-y: 15px;

                @media (min-width: 1201px) {
                    position: relative;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out calc(var(--anim-delay)),
                                transform 0s linear calc(var(--anim-delay) + 0.2s);

                    &:nth-child(1) {
                        transform: translateY(var(--anim-y));
                    }
                    &:nth-child(2) {
                        transform: translateY(calc(var(--anim-y) * 1.2));
                    }
                    &:nth-child(3) {
                        transform: translateY(calc(var(--anim-y) * 1.4));
                    }
                    &:nth-child(4) {
                        transform: translateY(calc(var(--anim-y) * 1.6));
                    }
                    &:nth-child(5) {
                        transform: translateY(calc(var(--anim-y) * 1.8));
                    }
                    &:nth-child(6) {
                        transform: translateY(calc(var(--anim-y) * 2));
                    }
                    &:nth-child(7) {
                        transform: translateY(calc(var(--anim-y) * 2.2));
                    }
                    &:nth-child(7) ~ li {
                        transform: translateY(calc(var(--anim-y) * 2.4));
                    }

                    .main-menu__sub._active & {
                        opacity: 1;
                        transform: translateY(0);
                        transition: all 0.3s ease;

                        &:nth-child(1) {
                            transition-delay: calc(var(--anim-delay) + var(--anim-dl));
                        }
                        &:nth-child(2) {
                            transition-delay: calc(var(--anim-delay) + var(--anim-dl) * 2);
                        }
                        &:nth-child(3) {
                            transition-delay: calc(var(--anim-delay) + var(--anim-dl) * 3);
                        }
                        &:nth-child(4) {
                            transition-delay: calc(var(--anim-delay) + var(--anim-dl) * 4);
                        }
                        &:nth-child(5) {
                            transition-delay: calc(var(--anim-delay) + var(--anim-dl) * 5);
                        }
                        &:nth-child(6) {
                            transition-delay: calc(var(--anim-delay) + var(--anim-dl) * 6);
                        }
                        &:nth-child(7) {
                            transition-delay: calc(var(--anim-delay) + var(--anim-dl) * 7);
                        }
                        &:nth-child(7) ~ li {
                            transition-delay: calc(var(--anim-delay) + var(--anim-dl) * 8);
                        }
                    }
                }
            }
        }
    }
    &__sub-bg-link {
        border-radius: 50px;
        position: relative;
        min-height: 232px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        text-align: center;
        will-change: opacity;

        @media (min-width: 1201px) {
            opacity: 0;
            transition: opacity 0.1s ease var(--anim-delay),
                        transform 0s linear calc(var(--anim-delay) + 0.1s);

            .main-menu__sub._active & {
                transform: translateY(0);
                opacity: 1;
                transition: all 0.2s ease-in-out calc(var(--anim-delay) + var(--anim-dur) * 1.8);
            }
        }
        @media (max-width: 1200px) {
            margin-top: auto;
            width: 351px;
            height: 160px;
            min-height: 160px;
            max-width: 100%;
            border-radius: 40px;
        }
        .h2,
        h2 {
            margin-top: 0;
            margin-bottom: 0;
            @media (max-width: 1200px) {
                font-size: 14px;
            }
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            transition: transform 0.3s;
            object-fit: cover;
        }
        &::after {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            content: "";
            z-index: 4;
            border-radius: inherit;
            background: rgba(0, 0, 0, 0.5);
        }
        span {
            position: relative;
            z-index: 5;
            color: #fff;
        }
        svg {
            z-index: 10;
            transition: transform 0.3s;
            position: absolute;
            right: 20px;
            bottom: 20px;

            @media (max-width: 1200px) {
                width: 40px;
                height: 40px;
            }
        }
        &:hover {
            img {
                transform: scale(1.02);
            }
            svg {
                transform: rotate(45deg);
            }
        }
    }
    &__btns {
        position: relative;
        z-index: 1;
        margin-top: auto;
        padding-bottom: 40px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        display: none;

        @media (max-width: 1200px) {
            display: flex;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.1s ease;

            body._burger-menu-open & {
                opacity: 1;
                transition: opacity 0.3s ease 0.6s;
                pointer-events: auto;
            }
        }

        body:has(.main-menu__sub._active) & {
            
            > * {
                opacity: 0;
                pointer-events: none;
                transition: none;
            }
        }

        a {
            display: inline-flex;
        }
    }
}

.theme-dark {
    .header {
        --color: #fff;
        --line: #373737;

        .inner {
            background: var(--dark);
        }

        &__logo-dark {
            display: none;
        }

        &__logo-light {
            display: block;
        }
    }

    .header__burger span {
        background: #fff;
    }

    .main-menu {
        &__sub {
            &:before {
                background: var(--dark);
            }
        }
    }
}

.header__special-version {

    @media (max-width: @screen-s) {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.1s ease, visibility 0s linear 0.1s;

        ._burger-menu-open & {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.1s ease;
        }
    }

    a {
        position: relative;
        overflow: hidden;

        span {

            &:nth-of-type(1) {
                display: none;
            }

            &:nth-of-type(2) {
                .v-hidden();
            }
        }
    }
}
