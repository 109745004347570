html.a11y-vision {

    .news-card {
        
    }

    .news-card__link {
        text-decoration: none;
        padding: 1em;
        border-radius: 1em;
        border: 2px solid var(--av-color-border);
        box-sizing: border-box;

        &:hover {
            .ally-vision-hover();
            border-width: 2px !important;
        }

        @media (max-width: @screen-s) {
            padding: 0.8em;
        }
    }

    .news-card__img-wrap {
        display: none;
    }

    .news-card__title {
        background: none;
        color: currentColor !important;
        margin: 0.5em 0;
        text-decoration: underline;
        text-decoration-color: var(--av-color-underline);

        @media (max-width: @screen-s) {
            font-size: 1.3em;
        }
    }

    .news-card__date {
        font-size: var(--av-font-size);
    }
}
