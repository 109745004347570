.map-marker {
    position: relative;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.ymaps-2-1-79-transport-pin__body {
    display: none;
}

