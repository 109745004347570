@import './layout.less';
@import './typography.less';

@import './components/footer.less';
@import './components/header.less';
@import './components/a11y-vision-menu.less';
@import './components/a11y-vision-menu-popup.less';
@import './components/breadcrumbs.less';
@import './components/shedule-item.less';
@import './components/select.less';
@import './components/card-preview.less';
@import './components/news-card.less';
@import './components/sliders.less';
@import './components/persona.less';
@import './components/droplist.less';
@import './components/notifications.less';

@import './pages/price.less';
@import './pages/schedule.less';
@import './pages/shemes.less';
@import './pages/glosarii.less';
@import './pages/contacts.less';
@import './pages/about.less';
@import './pages/cafe.less';
@import './pages/history.less';
@import './pages/events.less';
@import './pages/afisha.less';
@import './pages/event.less';
@import './pages/excursion.less';
@import './pages/lection.less';
@import './pages/blogs.less';
@import './pages/vacancies.less';
@import './pages/forms.less';
@import './pages/press.less';
@import './pages/visitors.less';
@import './pages/faq.less';
@import './pages/legend.less';
@import './pages/rules.less';
@import './pages/search.less';
@import './pages/zal.less';
@import './pages/podcasts.less';
@import './pages/podcast.less';
@import './pages/404.less';
@import './pages/home.less';
@import './pages/quiz.less';
@import './pages/partners.less';

html.a11y-vision {
    --body-bg: #fff !important;
    --dark: #000 !important;

    --av-font-size: 22px;
    --av-color-text: #000;
    --av-color-bg: #fff;
    --av-color-border: rgba(0, 0, 0, 0.3);
    --av-color-border-button: #000;
    --av-color-underline: rgba(0, 0, 0, 0.3);
    --av-font-family: Arial, sans-serif;
    // --av-font-family: Circe, Arial, sans-serif;

    body {
        font-size: var(--av-font-size);
        font-family: var(--av-font-family);
    }
    
    * {
        animation: none !important;
        font-family: var(--av-font-family);
        letter-spacing: normal !important;

        &:not(.a11y-vision-menu__full-menu)
        &:not(.faq__body) {
            transition: none !important;
        }
    }

    color {
        background-clip: unset !important;
        color: currentColor !important;
        background: none !important;
    }

    a {
        color: var(--av-color-text) !important;
        text-decoration: underline;
        text-decoration-color: var(--av-color-underline);
        border: 1px dashed transparent;
        transition: none;

        &:hover {
            color: var(--av-color-text) !important;
            text-decoration: none !important;
            background: rgba(0,0,0,0.07);
            border-color: currentColor;
        }
    }

    .btn-border,
    .btns-row .btn {
        border: 1px solid var(--av-color-border-button);
        font-size: var(--av-font-size);
        color: currentColor;
        text-decoration: none;
        background: none;

        &:hover {
            .ally-vision-hover();
        }

        &:before,
        &:after {
            content: none;
        }

        svg {
            display: none;
        }
    }

    .theme-dark {

        .header__logo-dark {
            display: block;
        }

        .header__logo-light {
            display: none;
        }
    }
}

.a11y-vision-button {
    display: inline-flex;
    align-items: center;
    height: 2em;
    border: 1px solid var(--av-color-border-button);
    border-radius: 1em;
    padding: 0 1em;
    font-weight: bold;
    background: none;
    color: var(--av-color-text);

    &:hover {
        .ally-vision-hover();
    }
}

.ally-vision-hover() {
    color: var(--av-color-text) !important;
    text-decoration: none !important;
    background: rgba(0,0,0,0.07) !important;
    border: 1px dashed currentColor;
}
