html.a11y-vision {

    .price-table,
    .table-resposive {
        font-size: var(--av-font-size);

        table {

            th, td {
                font-size: var(--av-font-size);
                border: 1px solid var(--av-color-border);
                padding: 1em 0.5em !important;
            }

            @media (max-width: @screen-s) {
                min-width: 600px;
            }
        }

        h2 {
            font-size: 1.7em;
            margin-top: 1em;

            @media (max-width: @screen-s) {
                font-size: 1.5em;
            }
        }

        p {
            max-width: 100%;
            font-size: inherit;
        }
    }

    .price-table__wrap {

    }

    .attention {
        border: 2px solid currentColor;

        h2, .h2 {
            margin-bottom: 1em;
        }
    }
}
