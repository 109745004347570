.page-quiz._scroll-top {
    .header .inner {
        background: transparent !important;
    }
}
.quiz {
    color: #fff;
    min-width: 0;
    &__quest-block {
        width: 100%;
        &._hidden {
            display: none;
        }
    }
    .quiz__next {
        margin-top: auto;
    }
    &__quest-result {
        display: none;
        width: 100%;
        &._show {
            display: block;
        }
        &._success {
            .h2,
            h2 {
                span.color-gradient,
                color {
                    background-clip: initial !important;
                    color: #5baf1d !important;
                    background: initial !important;
                    background-clip: initial !important;
                    -webkit-text-fill-color: initial !important;
                }
            }
        }
        &._error {
            .h2,
            h2 {
                span.color-gradient,
                color {
                    background-clip: initial !important;
                    color: #f84b3a !important;
                    background: initial !important;
                    background-clip: initial !important;
                    -webkit-text-fill-color: initial !important;
                }
            }
        }
    }
    &__quest-result-body {
        position: relative;
        margin-bottom: 40px;
        z-index: 7;
        color: #fff;
        max-width: 912px;
        margin-left: auto;
        width: 100%;
        margin-right: auto;
        border-radius: 50px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 50px;
        border: 1.6px solid var(--ffffff-white, #fff);
        background: linear-gradient(
            129deg,
            rgba(0, 0, 0, 0.03) 0.95%,
            rgba(255, 255, 255, 0.04) 98.9%
        );
        text-align: center;
        p,
        .h2,
        h2 {
            max-width: 695px;
            margin-left: auto;
            margin-right: auto;
        }

        backdrop-filter: blur(15px);
    }
    &__swiper-pagi {
        bottom: 40px !important;
        position: absolute;
        .swiper-pagination-bullet:first-child {
            display: none;
        }
        &:has(
                .swiper-pagination-bullet:first-child.swiper-pagination-bullet-active
            ) {
            display: none;
        }
        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            border: 1px solid #fff;
            margin-left: 9px;
            margin-right: 9px;
            background: transparent !important;
            &.swiper-pagination-bullet-active {
                background: #fff !important;
            }
        }
    }
    .breadcrumbs {
        position: relative;
        z-index: 3;
        li {
            color: #fff;
        }
    }
    &__requirements {
        p {
            transition: color 0.3s;
            .swiper-slide._error-validation & {
                color: red;
            }
        }
    }
    h1,
    .h1 {
        margin-top: 28px;
        margin-bottom: 25px;
    }
    &__bg {
        position: absolute;
        width: 100vw;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 40%;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.8) 0%,
                rgba(28, 28, 28, 0) 100%
            );
            display: block;
            content: "";
            z-index: 4;
        }
        &::after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
            display: block;
            content: "";
            z-index: 4;
        }
    }
    &__swiper {
        text-align: center;

        margin-left: auto;
        margin-right: auto;
        .swiper-slide {
            display: flex;
            flex-direction: column;
            min-height: calc(100svh - 215px);
            align-items: center;
            justify-content: center;
            padding-bottom: 84px;
            h2,
            .h2 {
                max-width: 1088px;
            }
            p {
                margin-top: 0;
            }
        }
    }
    &__photo-answers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
        padding: 0 2px;
        margin-top: 22px;
        max-width: 1088px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 22px;
    }
    &__photo-answer {
        text-align: left;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.36px;
        max-width: calc(25% - 12px);
        @media (max-width: 800px) {
            max-width: calc(50% - 10px);
        }
        .quiz__photo-answer-img {
            position: relative;
            line-height: 0;
            img {
                width: 100%;
            }
            margin-bottom: 10px;
        }
        position: relative;
        &:has(input:checked) {
            .quiz__photo-answer-img {
                box-shadow: 0 0 0 2px var(--link);
            }
            .quiz__photo-answer-number {
                transform: scale(1) translateY(-50%);
            }
        }
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 5;
        }
    }
    &__answers-text {
        max-width: 1160px;
        display: grid;
        gap: 16px;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        margin-left: auto;
        margin-right: auto;
        margin-top: 22px;
        margin-bottom: 22px;
    }
    &__answers {
        max-width: 678px;
        display: grid;
        gap: 8px;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        margin-left: auto;
        margin-right: auto;
        margin-top: 22px;
        margin-bottom: 22px;
    }
    &__photo-answer-number {
        color: var(--link);
        position: absolute;
        left: 0;
        width: 100%;
        top: 50%;
        transform: scale(0) translateY(-50%);
        text-align: center;
        font-family: Unbounded;
        font-size: 80px;
        font-style: normal;
        font-weight: 500;
        line-height: 0;
        display: block;
        text-align: center;
        transition: transform 0.3s;
    }
    &__answer-number {
        color: rgba(255, 255, 255, 0.3);
        position: absolute;
        left: 0;
        width: 100%;
        top: 50%;
        transform: scale(0) translateY(-50%);
        text-align: center;
        font-family: Unbounded;
        font-size: 80px;
        font-style: normal;
        font-weight: 500;
        line-height: 0;
        display: block;
        text-align: center;
        transition: transform 0.3s;
    }
    &__answer-text-number {
        color: rgba(255, 255, 255, 0.3);
        position: absolute;
        right: 16px;
        width: 100%;
        top: 50%;
        transform: scale(0) translateY(-50%);
        text-align: center;
        font-family: Unbounded;
        font-size: 80px;
        font-style: normal;
        font-weight: 500;
        line-height: 0;
        display: block;
        text-align: center;
        transition: transform 0.3s;
    }
    &__answer {
        position: relative;
        box-shadow: inset 0 0 0 1px;
        padding: 16px;
        background: linear-gradient(
            129deg,
            rgba(0, 0, 0, 0.03) 0.95%,
            rgba(255, 255, 255, 0.04) 98.9%
        );
        height: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(15.497076034545898px);
        font-family: Unbounded;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        border-radius: 50px;
        @media (max-width: 600px) {
            font-size: 16px;
            height: 83px;
        }
        .quiz__quest-result &,
        &:has(input:checked) {
            box-shadow: inset 0 0 0 0px;
            background: var(--gradient);
            .quiz__answer-number {
                transform: scale(1) translateY(-50%);
            }
        }
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }
    &__answer-text {
        position: relative;
        box-shadow: inset 0 0 0 1px;
        padding: 16px;
        padding-left: 54px;
        background: linear-gradient(
            129deg,
            rgba(0, 0, 0, 0.03) 0.95%,
            rgba(255, 255, 255, 0.04) 98.9%
        );
        min-height: 108px;
        display: flex;
        flex-direction: row;
        align-items: center;

        backdrop-filter: blur(15.497076034545898px);
        text-align: left;
        font-size: 16px;
        font-style: normal;

        color: #fff;
        border-radius: 20px;
        &::before {
            width: 22px;
            height: 22px;
            position: absolute;
            background: #fff;
            border-radius: 6px;
            left: 18px;
            top: 18px;
            display: block;
            content: "";
            border: 1px solid #cfcfcf;
        }
        &:has(input[type="radio"]) {
            &::before {
                border-radius: 50%;
            }
        }

        .quiz__quest-result &,
        &:has(input:checked) {
            box-shadow: inset 0 0 0 0px;
            background: var(--gradient);
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 3.5L4.5 6L8.5 1' stroke='url(%23paint0_linear_2479_34117)' stroke-width='1.6'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2479_34117' x1='0.541701' y1='2.6425' x2='9.4336' y2='3.40167' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FB8129'/%3E%3Cstop offset='1' stop-color='%23CD380E'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
                background-position: center;
                background-repeat: no-repeat;
            }
            .quiz__answer-text-number {
                transform: scale(1) translateY(-50%);
            }
        }
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        &:has(input[type="radio"]:checked)::before {
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='4' fill='url(%23paint0_linear_2427_35760)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2427_35760' x1='-1.0952' y1='2.628' x2='9.10312' y2='3.24994' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FB8129'/%3E%3Cstop offset='1' stop-color='%23CD380E'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A") !important;
        }
    }
}
.quiz-start {
    margin-left: auto;
    margin-right: auto;

    svg {
        position: relative;
        top: 0;
    }
}
