html.a11y-vision {

    .notification,
    .notification-cookie {
        background: var(--av-color-text);
        color: var(--av-color-bg);
        border-bottom-color: #fff;
        padding: 1em 0;
    }

    .notification__text,
    .notification-cookie__text {
        font-size: var(--av-font-size);
        font-weight: normal;
        text-transform: none;
        line-height: normal;
        color: var(--av-color-bg);

        a {
            color: currentColor !important;
            text-decoration: underline;
            border: 1px solid transparent;

            &:hover {
                color: var(--av-color-bg) !important;
                border: 1px dashed currentColor !important;
            }
        }

        b {
            color: currentColor;
        }
    }

    .notification-cookie {
        background: var(--av-color-text);
        box-shadow: none;
    }

    .notification__btn-close,
    .notification-cookie__btn-close {
        border: 1px solid currentColor;
        text-decoration: none;

        &:hover {
            border: 1px dashed currentColor;
            color: var(--av-color-bg);
        }
    }
}
