html.a11y-vision {

    .event-top {
        height: auto;
        min-height: 0;
        max-height: unset;
        padding: 0;
        flex-wrap: wrap;
        border-radius: 0 !important;

        @media (max-width: @screen-m) {
            padding-left: var(--inner-padding);
            padding-right: var(--inner-padding);
        }
    }
    
    .event-top__img {
        display: none;
    }

    .event-top__left {
        width: 100%;

        @media (max-width: 700px) {
            display: flex !important;
            margin-top: 0.5em;
        }
    }

    .event-top__right {
        width: 100%;
        margin-top: 1em;
        align-items: flex-start;
        gap: 1em;
    }

    .event-top__tags {
        color: var(--av-color-text);
        width: 100%;

        li {
            color: currentColor;
            box-shadow: inset 0 0 0 2px var(--av-color-border);
            background: none;
            font-weight: normal;

            span {
                font-size: var(--av-font-size);
            }

            svg {

                path {
                    stroke: currentColor;
                }
            }
        }
    }

    .top-row__btns {
        margin-top: 1em;
    }

    .event-top__awards {
        color: var(--av-color-text);
        display: none;
    }

    .event-top__award {

        &:before,
        &:after {
            content: none;
        }
    }

    .buy-bilet {
        background: none;
        border: 2px solid var(--av-color-border-button);
        min-height: 0;
        padding: 1em 0;
        max-width: 100%;
        text-decoration: none;
        margin-top: 2em;

        &:hover {
            .ally-vision-hover();
            border-width: 2px;
        }

        div[data-src],
        svg {
            display: none;
        }

        span {
            margin: 0;
            font-size: var(--av-font-size);
            font-weight: bold;
        }
    }

    .event-cards {
        margin-top: 4em;
        margin-bottom: 4em;
    }

    .event-card {
        padding: 0;
        border-radius: 0 !important;
    }

    .event-card__title {
        margin-top: 0;
        margin-bottom: 0.5em;
    }

    .event-card__img {
        display: none;
    }

    .awards-slider {

    }

    .award__content {
        width: 100%;
        height: auto;
        text-align: left;
        align-items: flex-start;
        padding: 0;

        &:before,
        &:after {
            content: none;
        }
    }

    .award__desc {
        text-align: left;
        align-items: flex-start;

        p {
            max-width: 100%;
            margin: 0;
        }
    }

    .award__top,
    .award__name,
    .award__year {
        font-size: var(--av-font-size);
    }

    .event-program {

    }

    .event-program__img {
        display: none;
    }

    .event-program__link {
        padding: 1.2em;
        border-radius: 1em;
        border: 2px solid var(--av-color-border);
        box-sizing: border-box;
        text-decoration: none;

        @media (max-width: @screen-s) {
            padding: 0.8em;
        }

        &:hover {
            .ally-vision-hover();
            border-width: 2px;
        }

        .event-program__name {
            text-decoration: underline;
            text-decoration-color: var(--av-color-underline);
        }
    }

    .event-program__name {
        font-size: 1.2em;
        font-weight: bold;
        margin-top: 0;
        background: none;
        color: var(--av-color-text) !important;
    }

    .event-program__desc {
        text-decoration: none;
    }

    .expert {
        background: none;
        color: var(--av-color-text);
        overflow: visible;
        padding: 1.2em;
        border-radius: 1em !important;
        border: 2px solid var(--av-color-border);
        box-sizing: border-box;
        text-decoration: none;

        @media (max-width: @screen-s) {
            padding: 0.8em;
        }

        h2 {
            margin-top: 0;
        }

        p {
            max-width: 100%;
        }
    }

    .expert__row {
        display: block;
    }

    .expert__left {
        color: currentColor;
        padding: 0;
    }

    .expert__icon {
        display: none;
    }

    .expert__expert {
        border-radius: 0 !important;
        overflow: visible;
        background: none;
        padding: 0;
        margin-top: 2em;

        img {
            display: none;
        }

        h2 {
            margin: 0;
            font-size: 1.2em;
        }
    }

    .white-block:has(.expert) {
        padding-top: 0;
    }
}
