.footer {
    margin-top: auto;
    background: var(--dark);
    color: var(--gray);
    border-radius: 50px 50px 0 0;
    padding-top: 60px;
    position: relative;

    @media (min-width: @screen-fix) {
        // width: 1900px;
        // margin-left: auto;
        // margin-right: auto;
    }

    @media (max-width: 700px) {
        border-radius: 30px 30px 0 0;
        padding-top: 50px;
    }

    &__top {
        display: grid;
        gap: 60px;
        grid-template-columns: 280px auto 364px;
        @media (max-width: 1400px) {
            grid-template-columns: 240px auto 320px;
            gap: 40px;
        }
        @media (max-width: 1300px) {
            grid-template-columns: auto 364px;
        }
        @media (max-width: 700px) {
            grid-template-columns: 1fr;
            gap: 0;
        }
    }
    &__logo {
        margin-bottom: 24px;
    }
    &__right {
        display: flex;
        flex-direction: column;

        @media (max-width: 700px) {
            flex-direction: column-reverse;
        }
    }
    &__title {
        color: #fff;

        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* */
        letter-spacing: -0.16px;
        margin-bottom: 10px;
    }
    &__info {
        @media (max-width: 700px) {
            text-align: center;
        }
        a {
            transition: color 0.2s ease;
            &:hover {
                color: var(--hover-red);
                transition: color 0.2s ease;
            }
        }
        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            margin: 0;
            margin-bottom: 15px;
            line-height: 150%; /*  */
            letter-spacing: -0.16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__menus {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 570px;

        @media (max-width: 1300px) {
            display: none;
        }
    }
    &__menu {
        letter-spacing: -0.01em;
        width: 150px;
        flex-shrink: 0;

        ul {
            display: flex;
            gap: 5px;
            list-style: none;
            flex-direction: column;
            padding-left: 0;
            li {
                list-style: none;
                padding-left: 0;
                margin-right: -30px;
            }
            a {
                &:hover {
                    color: var(--hover-red);
                }
                &::selection {
                    -webkit-text-fill-color: #fff !important;
                }
            }
        }
    }
    &__subs {
        margin-bottom: 20px;
        max-width: 390px;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        @media (max-width: 700px) {
            margin-bottom: 0;
        }
    }
    &__subs-bg {
        border-radius: 50px;
        background: var(--gradient);
        padding: 2px;
        margin-bottom: 12px;
    }

    &__subs-row {
        border-radius: 50px;
        background: var(--dark);
        display: flex;
        flex-direction: row;
        padding: 4px;
        display: flex;
    }
    &__subs-mail {
        padding-left: 10px;
        border: 0 solid;
        background: none;
        color: #fff;
        flex: 1;
        outline: none !important;
        &::placeholder {
            color: var(--gray);
        }
    }
    &__subs-btn {
        background: var(--gradient);
        width: 42px;
        height: 42px;
        border: 0 solid;
        border-radius: 50%;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        transition: all 0.2s ease;

        &:disabled {
            opacity: 0.5;
        }

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            background: #fff;
            opacity: 0;
            transition: inherit;
        }

        svg {
            width: 12px;
            height: 12px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -6px 0 0 -6px;
            z-index: 2;
        }

        span {
            .v-hidden();
        }

        html.desktop &:hover {
            color: var(--dark);

            &:before {
                opacity: 1;
            }
        }
    }
    &__subs-agree {
        position: relative;
        padding-left: 33px;
        display: block;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* */
        letter-spacing: -0.16px;
        user-select: none;
        a {
            color: var(--link);
            text-decoration: underline;
            text-decoration-color: currentColor;

            &:hover {
                text-decoration-color: transparent;
            }
        }
        input {
            position: absolute;
            left: 0;
            opacity: 0;
            top: 0;

            &:focus {
                ~ .footer__subs-agree-indicator {
                    box-shadow: inset 0 0 0 2px var(--link);
                }
            }

            &:checked {
                ~ .footer__subs-agree-indicator {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
        html.desktop &:hover {
            .footer__subs-agree-indicator {
                box-shadow: inset 0 0 0 2px var(--link);
            }
        }
    }
    &__subs-agree-text {
        display: block;
    }
    &__subs-agree-indicator {
        display: block;
        width: 22px;
        height: 22px;
        background: #fff;
        box-shadow: inset 0 0 0 2px #cfcfcf;
        border-radius: 6px;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.2s ease;
        cursor: pointer;
        overflow: hidden;

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: url("../../../static/img/checkbox.svg") no-repeat center
                    center,
                var(--gradient);
            opacity: 0;
            transition: opacity 0.1s ease;
        }
    }
    &__subs-message {
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--dark);
        left: 0;
        top: 0;
        z-index: 3;
        align-items: center;
        display: none;

        .footer__subs._is-sent & {
            display: flex;
        }
    }
    &__subs-message-text {
        color: #fff;
    }
    &__subs-message-inner {
        text-align: center;
        width: 100%;

        html.desktop & .btn {
            &:hover {
                color: var(--dark) !important;
            }
        }
    }
    &__socials {
        display: flex;
        flex-direction: row;
        list-style: none;
        gap: 14px;
        padding-left: 0;
        align-items: center;
        justify-content: flex-end;
        @media (max-width: 700px) {
            justify-content: center;
            padding-bottom: 24px;
            border-bottom: 1px solid #373737;
            margin-bottom: 30px;
            margin-top: 20px;
        }

        a {
            cursor: pointer;
            path {
                transition: fill 0.2s ease;
            }
            &:hover {
                path {
                    fill: var(--hover-red);
                }
            }
        }
    }
    &__links {
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        a:hover {
            color: #fff;
        }
        @media (max-width: 1100px) {
            position: relative;
            transform: none;
            left: 0;
        }
    }
    &__bottom {
        margin-top: 40px;
        border-top: 1px solid #373737;
        padding-top: 20px;
        padding-bottom: 30px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        letter-spacing: -0.12px;

        @media (max-width: 700px) {
            margin-top: 25px;
        }
    }
    &__bottom-row {
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 700px) {
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }
    }
    &__ctm {
        position: relative;
        overflow: hidden;

        span {
            display: none;
        }

        path {
            transition: fill 0.2s ease;
        }
        &:hover {
            path {
                fill: var(--hover-red);
            }
        }
    }
    &__copy {
        width: 469px;
        @media (max-width: 1100px) {
            width: 100%;
            border-bottom: 1px solid #373737;
            padding-bottom: 20px;
            margin-bottom: 20px;
            text-align: center;

            br {
                display: none;
            }
        }
        @media (max-width: 700px) {
            border: 0 solid;
            margin-bottom: 0px;
            padding-bottom: 0;

            span {
                display: block;
            }
        }
        span {
            color: #fff;
        }
    }
}
