html.a11y-vision {

    .cards-preview-grid {
        grid-template-columns: 1fr;
    }

    .card-preiview {
        display: block;
        aspect-ratio: unset;
        padding: 0;
        border-radius: 0 !important;
        overflow: visible;
    }

    .card-preiview__img-wrap {
        display: none;
    }

    .card-preiview__link {
        display: inline-block;
        font-size: var(--av-font-size);

        &:before,
        &:after {
            content: none;
        }
    }
}
