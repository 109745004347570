html.a11y-vision {

    .header {
        position: relative;
        top: 0 !important;
        font-size: var(--av-font-size);
        
        .inner {
            background: #fff !important;
        }

        a {
            text-decoration: underline;
            text-decoration-color: var(--av-color-underline);
            font-weight: bold;
        }
    }

    .header__overlay,
    .header__burger,
    .main-menu {
        display: none;
    }

    .header__top {
        border-bottom: 1px solid var(--av-color-border);
        border-bottom: 0;
        justify-content: space-between;
        width: 100%;

        @media (max-width: @screen-s) {
            flex-wrap: wrap;
        }
    }

    .header__logo {
        display: block;
        padding: 0.5em;
        width: 12em;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        margin: 0;
        opacity: 1 !important;
        pointer-events: auto !important;

        img {
            display: block;
            width: 100%;
        }

        @media (max-width: @screen-s) {
            width: 300px;
            padding: 0.2em;
        }
    }

    .header__left-menu {
        display: none;
    }

    .header__menu {
        gap: 1em;

        > *:not(.header__special-version) {
            display: none;
        }

        svg,
        .d-min-1200:first-child,
        .d-min-1200.divider {
            display: none;
        }

        svg + span {
            display: inline;
        }

        a {
            padding: 0 0.2em;
        }
    }

    .header__special-version {
        visibility: visible;
        opacity: 1;
        
        a {
            position: relative;
            text-decoration: none;
            border: 1px solid currentColor;
            border-radius: 2em;
            padding: 0 1em;
            height: 2em;
            line-height: normal;

            &:hover {
                .ally-vision-hover();
            }

            span {

                &:nth-of-type(1) {
                    display: inline;
                }
    
                &:nth-of-type(2) {
                    display: none;
                }
            }
        }
    }

    .header__right {

        @media (max-width: @screen-s) {
            width: 100%;
            margin-top: 1.5em;
        }
    }

    .header__btns {
        display: none;
    }
}
