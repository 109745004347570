.contact-block-1 {
    background: #fff;
    padding: 30px var(--inner-padding) 30px var(--inner-padding);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    width: calc(100vw * var(--w-correction));
    position: relative;
    left: 50%;
    margin-left: calc(-50vw * var(--w-correction));

    @media (min-width: @screen-fix) {
        width: 100%;
        left: 0;
        margin-left: 0;
    }

    @media (max-width: 992px) {
        grid-template-columns: 1fr;
    }

    @media (max-width: 700px) {
        padding: 12px;
        padding-top: 50px;
    }
    &__content {
        display: grid;
        gap: 40px 30px;
        grid-template-columns: 1fr 1fr;
        max-width: 640px;
        margin-top: 30px;
        @media (max-width: 700px) {
            gap: 25px 30px;
            max-width: unset;
            grid-template-columns: 1fr;
            margin-top: 0;
        }
    }
    &__map {
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        position: absolute;
    }
    &__map-wrap {
        position: relative;
        border-radius: 20px !important;
        &::before {
            display: block;
            content: "";
            padding-bottom: 73.53%;
        }
    }
    &__item {
        &._full {
            grid-column: span 2;
        }
        @media (max-width: 700px) {
            grid-column: span 1 !important;
        }
        .h2,
        h2 {
            margin-top: 0;
            margin-bottom: 0;
            max-width: 620px;
        }
        p {
            margin-top: 0;
            margin-bottom: 4px;

            a:not([href^="tel:"]) {
                color: var(--link);
                text-decoration: underline;
                transition: all 0.2s ease;

                html.desktop &:hover {
                    text-decoration-color: transparent;
                    color: var(--hover-red);
                }
            }

            a[href^="tel:"] {
                transition: all 0.2s ease;
                html.desktop &:hover {
                    color: var(--hover-red);
                }
            }
        }
        // a color {
        //     background: none;
        //     background-clip: unset !important;
        //     -webkit-text-fill-color: unset !important;
        // }
    }
    &__item-title {
        color: #878787;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 10px;
        letter-spacing: -0.16px;
    }
}

.contact-block-2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 22px;
    margin-top: 22px;
    margin-bottom: 100px;

    @media (max-width: 1600px) {
        gap: 16px;
        margin-top: 16px;
    }
    @media (max-width: 1100px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 992px) {
        gap: 12px;
        margin-top: 12px;
        display: flex;
        grid-template-columns: 1fr;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        scroll-padding: 0 0 0 var(--inner-padding);
        flex-direction: row;
        padding: 0 var(--inner-padding);
        margin-left: calc(-1 * var(--inner-padding));
        margin-right: calc(-1 * var(--inner-padding));
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    @media (max-width: 700px) {
        margin-bottom: 60px;
    }
    &__item {
        background: #fff;
        padding: 70px 30px 40px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        scroll-snap-align: start;
        @media (max-width: 992px) {
            min-width: min(90%, 450px);
            padding: 30px 20px;
        }
        @media (max-width: 450px) {
            min-width: 94%;
        }
        > * {
            width: 100%;
        }
    }
    &__item-img-wrap {
        margin-bottom: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;
        width: 40%;
        flex-shrink: 0;
        position: relative;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: absolute;
            left: 0;
            top: 0;
        }
        @media (max-width: 600px) {
            width: 90px;
            height: 90px;
            margin-left: auto;
            img {
                width: 100%;
            }
        }
    }
    &__item-title {
        font-family: Unbounded;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        margin-top: 50px;
        margin-bottom: 0;

        @media (max-width: 992px) {
            margin-top: 30px;
        }

        a {
            display: inline-block;

            html.desktop &:hover {
                color: var(--hover-red);
            }
        }
    }
    &__item-desc {
        color: #878787;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.16px;
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
        a {
            color: var(--link);
            text-decoration: underline;
            transition: all 0.2s ease;

            html.desktop &:hover {
                text-decoration-color: transparent;
            }
        }
    }
}
.contact-block-3 {
    margin-bottom: 16px;
    padding: 20px var(--inner-padding);
    background: #fff;
    display: grid;
    grid-template-columns: 7fr 5fr;
    gap: 40px;
    @media (max-width: 700px) {
        width: calc(100vw * var(--w-correction));
        position: relative;
        left: 50%;
        margin-left: calc(-50vw * var(--w-correction));
        padding: 12px var(--inner-padding);
        gap: 30px;
    }
    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
    &__left {
        display: flex;
        flex-direction: column;
        padding-top: 24px;

        & > h2,
        & > .h2 {
            margin-top: 0;
            @media (max-width: 1024px) {
                margin-bottom: 50px;
            }
        }
        @media (max-width: 1024px) {
            padding-left: 0;
        }
    }
    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: auto;
        gap: 15px;
        max-width: 640px;
        @media (max-width: 1250px) {
            grid-template-columns: 1fr;
        }
        @media (max-width: 1024px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    }
    &__item {
        h2,
        .h2 {
            margin-top: 0px;
            margin-bottom: 10px;
            & + p {
                margin-top: 10px;
            }
        }
        p {
            color: #878787;
        }
    }
    &__right {
        padding: 30px;
        color: #fff;
        background: var(--gradient);
        h2,
        .h2 {
            margin-top: 20px;
        }
        @media (max-width: 1024px) {
            padding: 30px 20px;
        }
    }
}

.contact-block-4 {
    padding: 20px var(--inner-padding);
    background: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        gap: 30px;
        padding-top: 50px;
    }
    @media (max-width: 700px) {
        width: calc(100vw * var(--w-correction));
        position: relative;
        left: 50%;
        margin-left: calc(-50vw * var(--w-correction));
        padding: 40px var(--inner-padding) 12px var(--inner-padding);
    }
    &__left {
        padding: 14px 0;
        justify-content: flex-end;
        max-width: 640px;
        @media (max-width: 1024px) {
            padding: 0;
        }
        p {
            color: #878787;
        }
        h2,
        .h2 {
            margin-top: 0;
            margin-bottom: 0;
        }
        display: flex;
        flex-direction: column;
        p:last-child {
            margin-bottom: 0;
        }
    }
    &__map {
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        position: absolute;
    }
    &__map-wrap {
        position: relative;
        border-radius: 20px !important;
        &::before {
            display: block;
            content: "";
            padding-bottom: 73.53%;
        }
    }
}

