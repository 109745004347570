html.a11y-vision {

    .vacancy {
        padding: 0;
        border-radius: 0;
        overflow: visible;

        + .vacancy {
            // border-top: 1px solid var(--av-color-border);
        }
    }

    .vacancies-list {
        gap: 2em;
    }

    .vacancy__top {
        padding-bottom: 0;
        padding-right: 0;
        border-bottom: 1px solid var(--av-color-border);

        h2 {
            display: inline-block;
            border: 1px solid transparent;
            text-decoration: underline;
            text-decoration-color: var(--av-color-underline);
        }

        &:hover {
            

            h2 {
                text-decoration: none;
                .ally-vision-hover();
            }
        }
    }

    .vacancy__bottom {
        // border: 0;
        border-top-color: var(--av-color-border);
        flex-direction: column;
        align-items: flex-start;
        gap: 1em;
        display: flex !important;
    }

    .vacancy__send {
        margin-left: unset;
    }

    .vacancy__bottom-list {
        list-style: disc;
        gap: 0.5em;
        padding-left: 1em;
        margin-bottom: 0;
        display: flex !important;

        li {
            width: 100%;

            &:after {
                content: none;
            }
        }
    }

    .vacancy__grid {
        columns: unset;
    }

    .vacancy__block {
        padding: 0;

        h2 {
            color: var(--av-color-text);
        }
    }

    .vacancy__arrwow {
        display: none;
    }
}
