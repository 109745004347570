html.a11y-vision {

    .contact-block-1 {
        display: block;
        width: 100%;
        left: 0;
        margin-left: 0;
        padding: 0;
        border-radius: 0 !important;
    }

    .contact-block-1__item-title {
        color: currentColor;
        font-size: var(--av-font-size);
    }

    .contact-block-1__item {

        p a {
            text-decoration-color: var(--av-color-underline);
        }
    }

    .contact-block-1__content {
        max-width: 100%;
    }

    .contact-block-1__map-wrap {
        margin-top: 3em;
        border: 2px solid currentColor;
        aspect-ratio: 2;

        @media (max-width: @screen-s) {
            aspect-ratio: 1.5;
        }
    }


    .contact-block-2 {
        display: grid !important;
        grid-template-columns: 1fr;
        gap: 3em;
        margin-top: 4em;
    }

    .contact-block-2__item-img-wrap {
        display: none;
    }

    .contact-block-2__item {
        padding: 0;
        border-radius: 0 !important;
    }

    .contact-block-2__item-title {
        margin-top: 0;
        font-weight: bold;
        font-size: 1.2em;
    }

    .contact-block-2__item-desc {
        color: var(--av-color-text);
    }

    .contact-block-3 {
        grid-template-columns: 1fr;
        padding: 0;
        border-radius: 0 !important;

        @media (max-width: @screen-s) {
            padding-left: var(--inner-padding);
            padding-right: var(--inner-padding);
        }
    }

    .contact-block-3__grid {
        max-width: 100%;
        grid-template-columns: 1fr 1fr;

        @media (max-width: @screen-m) {
            grid-template-columns: 1fr;
        }
    }

    .contact-block-3__item {

        p {
            color: currentColor
        }
    }

    .contact-block-3__right {
        background: none;
        color: var(--av-color-text);
        border: 2px solid currentColor;
        border-radius: 1em !important;

        svg {
            display: none;
        }
    }

    .contact-block-4 {
        padding: 0;
        grid-template-columns: 1fr;
        border-radius: 0 !important;

        @media (max-width: @screen-s) {
            padding-left: var(--inner-padding);
            padding-right: var(--inner-padding);
        }
    }

    .contact-block-4__left {
        max-width: 100%;
        margin-top: 2em;

        p {
            color: currentColor;
        }
    }

    .contact-block-4__map-wrap {
        aspect-ratio: 2;
        border: 2px solid currentColor;

        @media (max-width: @screen-s) {
            aspect-ratio: 1.5;
        }
    }
}
