body {
    font-family: Circe;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

h1,
.h1 {
    & + h2,
    & + .h2 {
        margin-top: 0;
    }
}
.h1-big {
    font-size: 400px;
    line-height: normal;
    .clampf(36,70);
    text-transform: uppercase;
    font-family: Unbounded;
    font-weight: 700;
    letter-spacing: -0.46px;
    span {
        color: #444;
    }
    .white-block & {
        color: #b7b7b7;
    }
}
h1,
.h1 {
    font-size: 400px;
    line-height: normal;
    .clampf(24,46);
    font-family: Unbounded;
    font-weight: 700;
    letter-spacing: -0.46px;
    a&.title-link {
        display: inline-flex;
        align-items: center;
        .color();
        color: var(--dark);
        -webkit-text-fill-color: unset;

        @media (max-width: 1024px) {
            width: 100%;
        }
        &::after {
            height: 32px;
            width: 18px;
            display: block;
            content: "";
            margin-left: 12px;
            transition: transform 0.5s;
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='32' viewBox='0 0 18 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.1875 30.5846L16.8926 16.0013L1.1875 1.41797' stroke='url(%23paint0_linear_1605_4247)' stroke-width='1.4'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1605_4247' x1='-0.962529' y1='10.9992' x2='19.111' y2='11.6584' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FB8129'/%3E%3Cstop offset='1' stop-color='%23CD380E'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
            display: inline-block;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            top: 4px;
            @media (max-width: 1024px) {
                height: 23px;
                margin-left: auto;
            }
        }
        &:hover {
            color: transparent;
            &::after {
                transform: translateX(5px);
            }
        }
    }
}
.block {
    .h1 {
        margin-top: 80px;
        margin-bottom: 40px;
        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
    }
}
h2,
.h2 {
    font-family: Unbounded;
    .clampf(20,24);
    font-style: normal;
    font-weight: 500;
    margin-top: 52px;
    margin-bottom: 16px;
    line-height: 133.333%;
}
h3,
.h3 {
    font-family: Unbounded;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 133.333%;
}
p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%; /* */
    letter-spacing: -0.36px;
}
.text {
    max-width: 909px;
    img {
        max-width: 100%;
    }
    table {
        td,
        tr,
        thead,
        th {
            height: initial !important;
        }
        thead {
            h3,
            .h3 {
                margin-top: 0;
                margin-bottom: 0;
            }
            td {
            }
        }
    }
    table {
        td {
            font-size: 16px;
        }
        width: 100%;
        tbody {
            td {
                min-width: 160px;
                padding: 10px;
                padding-top: 20px;
                padding-bottom: 0;
                &:first-child {
                    padding-left: 30px;
                    border-radius: 50px 0 0 50px;
                    @media (max-width: 992px) {
                        padding-left: 24px;
                    }
                    @media (max-width: 700px) {
                        padding-left: 12px;
                    }
                }
                &:last-child {
                    padding-right: 30px;
                    border-radius: 0 50px 50px 0;
                    @media (max-width: 992px) {
                        padding-right: 24px;
                    }
                    @media (max-width: 700px) {
                        padding-right: 12px;
                    }
                }
            }
        }
        thead {
            td {
                padding: 10px;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                background: #fff;
                letter-spacing: -0.16px;
                line-height: 24px;
                white-space: nowrap;
                &:first-child {
                    padding-left: 30px;
                    border-radius: 50px 0 0 50px;
                    @media (max-width: 992px) {
                        padding-left: 24px;
                    }
                    @media (max-width: 700px) {
                        padding-left: 12px;
                    }
                }
                &:last-child {
                    padding-right: 30px;
                    border-radius: 0 50px 50px 0;
                    @media (max-width: 992px) {
                        padding-right: 24px;
                    }
                    @media (max-width: 700px) {
                        padding-right: 12px;
                    }
                }
            }
        }
    }
    .table-resposive {
        margin-top: 40px;
        padding-left: var(--inner-padding);
        padding-right: var(--inner-padding);
        margin-left: calc(-1 * var(--inner-padding));
        margin-right: calc(-1 * var(--inner-padding));
        overflow-x: auto;
        margin-bottom: 30px;
        @media (max-width: 992px) {
            padding-left: 0;
            padding-right: 0;
        }
        td {
            vertical-align: top;
        }
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 1em 0;
        li {
            position: relative;
            padding-left: 34px;
            &::before {
                width: 4px;
                height: 4px;
                position: absolute;
                left: 10px;
                top: 10px;
                display: block;
                content: "";
                border-radius: 50%;
                background: var(--gradient);
            }
        }
    }
    p {
        margin-bottom: 16px;
        em,
        big {
            font-family: Unbounded;
            font-size: 24px;
            font-weight: 300;
            font-style: normal;
            .clampf(20, 24);
            background: var(--gradient);
            background-clip: text;
            -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            color: transparent;
        }
    }
    a:not([class]) {
        color: var(--link);

        html.desktop &:hover {
            color: var(--hover-red);
        }
    }
    & > p > img {
        display: block;
        max-width: 100%;

        ._br();
    }
    & > p > b > img ,
    & > p > strong > img {
        display: block;
        max-width: 100%;

        ._br();
    }
    > img {
        display: block;
        max-width: 100%;
        margin: 40px 0;
        ._br();
    }
}

.blockquote {
    font-family: Unbounded;
    .clampf(20,24);
    font-style: normal;
    font-weight: 300;
    line-height: 133.333%;
    margin: 16px 0;
    padding: 0;
    padding-left: 16px;
    border-left: 1px solid var(--link);
    &__content {
        .color();
    }
}
