html.a11y-vision {

    .personal-grid {
        grid-template-columns: 1fr;
        gap: 3em;
    }

    .persona {
        overflow: visible;
    }

    .persona__img-wrap {
        display: none;
    }

    .persona__name {
        font-weight: bold;
        font-size: 1.3em;
    }

    .persona__date {
        background: none;
        padding-left: 0;
    }

    .swiper-slide {

        .persona {
            margin-bottom: 3em;
        }
    }
}
