.feeback {
    form {
        max-width: 680px;
        .btn-border {
            margin-top: 20px;
        }
        @media (max-width: 992px) {
            max-width: 100%;
        }
    }
    &__badge {
        background: var(--gradient);
        margin: 0 auto;
        width: 216px;
        min-height: 200px;
        padding: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 992px) {
            width: 100%;
            min-height: 160px;
        }
        p {
            font-family: Unbounded;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            color: #fff;
            margin-top: 0;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    h2,
    .h2 {
        &:first-child {
            margin-top: 0;
        }
    }
    &__left:first-child {
        p {
            margin-bottom: 14px;
        }
        h3,
        .h3 {
            margin-top: 0;
            margin-bottom: 40px;
        }
        p:first-child {
            margin-top: 0;
        }
    }
    &__row2 {
        display: grid;
        grid-template-columns: auto 600px;
        gap: 40px;
        @media (max-width: 1300px) {
            grid-template-columns: 1fr 216px;
        }
        @media (max-width: 992px) {
            grid-template-columns: 1fr;
        }
    }
    &__row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        @media (max-width: 992px) {
            gap: 50px;
            grid-template-columns: 1fr;
        }
        form {
            max-width: 560px;
            .form-item + .checkbox {
                margin-top: 30px;
            }
        }
    }
}

.form-success {
    text-align: center;
    .text {
        margin-left: auto;
        margin-right: auto;

        p {
            @media (max-width: 992px) {
                font-size: 16px;
            }
        }
    }

    &__img {
        margin-top: 50px;
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 100%;
    }
}
.feedback-link {
    margin: 40px 0;
    a {
        font-family: Unbounded;
        font-size: 24px;
        text-decoration: underline;
        @media (max-width: 992px) {
            font-size: 20px;
        }
        &:hover {
            text-decoration: none;
        }
    }
}

.form-item-row-two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 16px;
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
}
