.text ul.lections-list,
.lections-list {
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;
    max-width: 929px;
    @media (max-width: 1600px) {
        gap: 16px;
    }
    @media (max-width: 992px) {
        gap: 12px;
    }

    @media (max-width: 600px) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: none;
        margin-left: calc(var(--inner-padding) * -1);
        margin-right: calc(var(--inner-padding) * -1);
        padding-right: var(--inner-padding);
        padding-left: var(--inner-padding);
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    li {
        @media (max-width: 600px) {
            max-width: 350px;
            min-width: calc(100vw - 60px);
            padding: 23px 30px;
        }

        padding: 30px;
        margin: 0;
        p:last-child {
            margin-bottom: 0;
        }
        background: #fff;
        .h2,
        h2 {
            margin-bottom: 10px;
            margin-top: 0;
        }
    }
}
.lections-prices {
    padding-bottom: 40px;
    border-bottom: 1px solid #cfcfcf;
    @media (max-width: 700px) {
        padding-bottom: 0px;
        border-bottom: 0 solid;
    }
    &__swiper {
        .swiper-slide {
            padding-bottom: 5px;
        }
    }
    &__label {
        background: var(--gradient);
        color: #fff;
        display: flex;
        padding: 16px;
        text-align: center;
        position: absolute;
        right: 0;
        top: 0;
        width: 216px;
        height: 202px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 700px) {
            position: relative;
            width: 100%;
            margin-top: 60px;
        }
        span {
            font-family: Unbounded;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            display: block;
            margin-bottom: 10px;
        }
        a {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.16px;
            text-decoration-line: underline;
            &::after {
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
    &__body {
        position: relative;
        padding-right: 250px;
        @media (max-width: 700px) {
            padding-right: 0;
        }
    }
    &__head {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 80px;
        margin-bottom: 45px;
        gap: 20px;
        margin-top: 0;
        .h1,
        h1 {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: auto;
        }
    }
    &__filter {
        border-radius: 30px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        padding: 11px 20px;
        font-weight: 700;
        border: 0 solid;
        line-height: 150%;
        background: transparent;
        letter-spacing: -0.16px;
        &._active {
            background: var(--gradient);
            color: #fff;
        }
    }
}

.lections-price {
    h2,
    .h2 {
        &:first-child {
            margin-top: 0;
        }
    }
    .text {
        max-width: 1025px;
    }
    &__price-row {
        max-width: 1025px;
        margin: 16px 0;
    }
    &__tags {
        max-width: 1025px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        gap: 2px 10px;
        margin-top: 16px;
        margin-bottom: 16px;

        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-family: Circe;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            gap: 10px;
            letter-spacing: -0.16px;
            path[stroke] {
                stroke: url(#Gradient);
            }
            path[fill] {
                fill: url(#Gradient);
            }
        }
    }
}

.book-widget {
    background: #fff;
    max-width: 910px;
    padding: 0px 0px;
    margin-bottom: 30px;
    // .book-widget__title
    &__title {
    }

    // .book-widget__head
    &__head {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e7e4e5;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    // .book-widget__day-wrap
    &__day-wrap {
        text-align: center;
        position: relative;
        &:has(.book-widget__day._disable) {
            color: rgba(15, 15, 15, 0.5) !important;
            pointer-events: none;
            touch-action: none;
        }
    }

    // .book-widget__day-title
    &__day-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 171%;
        letter-spacing: -0.01em;
    }

    // .book-widget__day
    &__day {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-weight: 700;
        font-size: 14px;
        line-height: 171%;
        letter-spacing: -0.01em;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0 solid;
        font-family: "Circe", sans-serif;
        background: transparent;
        line-height: 0;
        &._disable {
            color: rgba(15, 15, 15, 0.5);
        }
        &._active {
            color: #fff;
            background: var(--gradient);
        }
        &:hover {
            box-shadow: inset 0 0 2px 0 #cfcfcf;
        }
    }

    // .book-widget__slider
    &__slider {
    }

    // .book-widget__list
    &__list {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        gap: 30px 40px;
        @media (max-width: 992px) {
            gap: 25px 30px;
        }
    }

    // .book-widget__item
    &__item {
        position: relative;
    }

    // .book-widget__item-link
    &__item-link {
        border: 1.6px solid #e7e4e5;
        border-radius: 50px;
        padding: 8px 12px;
        display: block;
        font-weight: 700;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: -0.01em;
        text-align: center;
        color: #000;
        margin-bottom: 10px;
        background-color: #fbfbfb;
        &:hover {
            border-color: #cfcfcf;
        }
        &::after {
            width: 100%;
            height: 100%;
            position: relative;
            left: 0;
            top: 0;
            display: block;
            content: "";
        }
    }

    // .book-widget__item-price
    &__item-price {
        font-weight: 700;
        font-size: 14px;
        line-height: 171%;
        letter-spacing: -0.01em;
        text-align: center;
        white-space: nowrap;
    }
}

