.blog-person {
    // position: sticky;
    // top: 200px;
    &__img {
        display: block;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin-bottom: 24px;
    }

    @media (max-width: 1024px) {
        width: 300px;
    }

    @media (max-width: 600px) {
        width: 100%;
    }

    p {
        margin: 10px 0;
        @media (max-width: 600px) {
            font-size: 16px;
        }
    }

    + .blog-person {
        margin-top: 24px;

        @media (max-width: 1024px) {
            margin-top: 0;
        }

        @media (max-width: 600px) {
            margin-top: 20px;
        }
    }
}

.blog-date {
    font-size: 18px; @media (max-width: 1024px) {
            margin-top: -30px; 
        }
    margin-top: -80px;
}
