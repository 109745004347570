:root {
    scroll-behavior: smooth;
}

html {
    margin: 0;
    padding: 0;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    font-family: var(--font-default);
    font-size: 1px;
    line-height: 1.3;
    font-weight: normal;
    height: 100%;
    min-height: 320px;
    min-width: 320px;
    color: #000;
    &:not(.mobile) {
        // .scrollbar();
    }

    &._has-no-visible-focus {
        * {
            outline: 0 !important;
        }
    }
    ._burger-menu-open {
        overflow: hidden;
    }
    @media (min-width: 1921px) {
        // font-size: 0.052vw;
    }
    &:has(.filter-popup._open) {
        @media (max-width: 1024px) {
            overflow: hidden;
            body {
                overflow: hidden;
            }
        }
    }
    &:not(.a11y-vision) {
        ::selection {
            color: #fff;
            background: #fb8129;
        }
    }
}
* {
    box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    font-size: 18rem;
    background: var(--body-bg);
    color: #000;
}
button {
    font-family: Circe;
}

// Placeholders
::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
    opacity: 1;
}

::-moz-placeholder {
    color: rgba(0, 0, 0, 0.4);
    opacity: 1;
}

// :disabled
:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.7;
    user-select: none;
}

// Simple tags
img {
    margin: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
    color: currentColor;

    &[href^="mailto"],
    &[href^="tel"] {
        user-select: text;
    }
}

ul {
    padding: 0;
    margin: 0;
    nav & {
        margin: 0;
        padding: 0;
    }
}

button,
input[type="checkbox"],
input[type="radio"] {
    cursor: pointer;
}

textarea,
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="password"],
input:not([type]) {
    -webkit-appearance: none;
}

textarea {
    html.desktop & {
        .scrollbar();
    }
}

select {
    // appearance: none;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

figure,
figcaption {
    margin: 0;
    padding: 0;
}

template {
    display: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

p {
    margin: 1em 0;
}
span.color-gradient,
color {
    .color();
}
.wrap-page {
    display: flex;
    flex-direction: column;
    min-height: 100svh;
    padding-top: 160px;
    @media (max-width: 1200px) {
        padding-top: 87px;
    }
}

.d-min-1400 {
    @media (max-width: 1400px) {
        display: none !important;
    }
}
.d-min-1200 {
    @media (max-width: 1200px) {
        display: none !important;
    }
}
.d-max-1200 {
    @media (min-width: 1200px) {
        display: none !important;
    }
}
.d-min-700 {
    @media (max-width: 700px) {
        display: none !important;
    }
}
.d-min-760 {
    @media (max-width: 760px) {
        display: none !important;
    }
}
.d-min-1024 {
    @media (max-width: 1024px) {
        display: none !important;
    }
}
.d-max-1024 {
    @media (min-width: 1023px) {
        display: none !important;
    }
}

.swiper-pagination-bullets {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    justify-content: center;
}
.swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    background: #fff !important;
    opacity: 1 !important;
    .white-block & {
        background: #e7e4e5 !important;
    }
    &.swiper-pagination-bullet-active {
        background: var(--gradient) !important;
    }
}
