.page-zal {
    .black-block {
        padding-bottom: 100px;
        margin-bottom: 0;
    }
    .history-img-row__item p {
        color: #0f0f0f;
    }
    .white-block {
        margin-top: -90px;
    }
    .black-block {
        .history-6 {
            margin-top: 30px;
        }
    }
    .history-1 {
        .h1-big {
            margin-bottom: 30px;
    
            @media (max-width: 1024px) {
                margin-bottom: 20px;
            }
    
            @media (max-width: 700px) {
                font-size: 28px;
            }
        }
    }
    .gallery-slider {
        margin-bottom: 160px;
    }
    .history-6 {
        .h1 {
            color {
                color: #fff;
                background: none;
                background-clip: unset;
                -webkit-text-fill-color: unset;
            }
        }
    }
}

.another-item {
    padding: 50px 30px 30px 30px;
    aspect-ratio: 330/366;
    background: #e7e4e5;
    display: flex;
    flex-direction: column;
    position: relative;
    &__img-wrap {
        display: flex;
        margin-bottom: auto;
        width: 60%;
        flex-shrink: 0;
        aspect-ratio: 1.5;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        svg {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &__link {
        font-family: Unbounded;
        .clampf(20,24);
        font-style: normal;
        font-weight: 500;
        line-height: 1.3;
        padding-right: 50px;
        position: relative;
        transition: color 0.4s;
        &::after {
            width: 100vmax;
            height: 100vmax;
            left: 50%;
            top: 50%;
            position: absolute;
            display: block;
            content: "";
            transform: translateX(-50%) translateY(-50%);
        }
        &::before {
            display: block;
            position: absolute;
            right: 5px;
            bottom: 0px;
            transition: transform 0.4s;
            content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.33203 14.4154L14.6654 1.08203M14.6654 1.08203V13.882M14.6654 1.08203H1.86536' stroke='%230F0F0F' stroke-width='2'/%3E%3Cpath d='M14.6672 13.882V1.08203H1.86719' stroke='%230F0F0F' stroke-width='2'/%3E%3C/svg%3E%0A");
        }
        &:hover {
            color: var(--link);
            &::before {
                transform: rotate(45deg);
            }
        }
    }
}
