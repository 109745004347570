html.a11y-vision {

    .shedule-item {
        display: block;
        border: 2px solid var(--av-color-border);
        padding: 1.2em;
        border-radius: 1em;
        margin-bottom: 2em;

        @media (max-width: @screen-s) {
            padding: 0.8em;
        }

        > div {

            &:first-child {
                display: none;
            }
        }
    }

    .shedule-item__content {
        padding-top: 0;

        > p {
            max-width: 32em;
        }
    }

    .shedule-item__title {
        margin-top: 0;
        background: none;

        @media (max-width: @screen-s) {
            font-size: 1.3em;
        }
    }

    .shedule-item__shedule {
        border-top: 1px solid var(--av-color-border);


    }

    .shedule-item__info {
        gap: 0.5em 2em;

        li {
            font-size: var(--av-font-size);

            > span[class^="icon-"] {
                height: 1.5em;

                svg {
                    width: auto;
                    height: 1.5em;
                }
            }
        }
    }

    .shedule-item__slots {
        font-size: var(--av-font-size);
        gap: 2em 2em;

        @media (max-width: 992px) {
            flex-wrap: wrap;
        }

        @media (max-width: @screen-s) {
            gap: 2em 1.4em;
        }

        p {
            // font-size: 0.8em;
            text-align: center;
        }
    }

    .shedule-item__slot-buy {
        font-size: 1em;
        box-sizing: border-box;
        border: 1px solid var(--av-color-border-button);

        &:hover {
            .ally-vision-hover();
        }
    }
}
