.press {
    &__filters {
        margin-bottom: 54px;
        user-select: none;
    }

    &__years {
        display: flex;
        flex-direction: row;
    }

    &__year {
        display: inline-table;
        position: relative;
        padding: 11px 20px;
        cursor: pointer;
        border-radius: 30px;
        letter-spacing: -0.16px;
        font-weight: 700;
        overflow: hidden;
        transition: color 0.3s;
        &:has(input:checked) {
            color: #fff;
            pointer-events: none;
            &::before {
                opacity: 1;
            }
        }
        html.desktop &:hover {
            color: var(--hover-red);
        }
        span {
            position: relative;
            z-index: 2;
        }
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            opacity: 0;
            display: block;
            content: "";
            transition: opacity 0.3s;
            height: 100%;
            background: var(--gradient);
        }
        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    &__months {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
    }

    &__month {
        display: inline-table;
        position: relative;
        padding: 11px 20px;
        cursor: pointer;
        border-radius: 30px;
        letter-spacing: -0.16px;
        font-weight: 700;
        overflow: hidden;
        transition: color 0.3s;
        &:has(input:checked) {
            color: #fff;
            pointer-events: none;
            &::before {
                opacity: 1;
            }
        }
        html.desktop &:hover {
            color: var(--hover-red);
        }
        span {
            position: relative;
            z-index: 2;
        }
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            opacity: 0;
            display: block;
            content: "";
            transition: opacity 0.3s;
            height: 100%;
            background: var(--gradient);
        }
        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    &__list {
    }
}
.press-item {
    margin-bottom: 50px;
    display: grid;
    gap: 15px;
    max-width: 1400px;
    grid-template-columns: max(20%, 250px) max(20%, 250px) auto;
    grid-template-areas: "date info link";
    @media (max-width: 1040px) {
        grid-template-columns: max(20%, 250px) auto;
        grid-template-areas: "date  link" "info link";
    }

    @media (max-width: 650px) {
        margin-bottom: 50px;
        gap: 12px;
        grid-template-columns: 1fr;
        grid-template-areas: "date" "info" "link";
    }
    &__date {
        color: #878787;
        font-family: Unbounded;
        font-size: 18px;
        font-weight: 500;
        grid-area: date;
    }

    &__info {
        grid-area: info;
    }

    &__name {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.16px;
    }

    &__type {
        letter-spacing: -0.16px;
        color: rgba(0, 0, 0, 0.5);
    }

    &__link-wrap {
        grid-area: link;

        a {
            display: inline-block;
            color: var(--link);
            text-decoration: underline;
            transition: all 0.2s ease;

            html.desktop &:hover {
                text-decoration-color: transparent;
            }
        }
    }
}

#press-template {
    display: none;
}
