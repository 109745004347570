html.a11y-vision {
    
    .visitors-grid {
        grid-template-columns: 1fr;
        gap: 1em;
    }

    .visitors-preview {
        grid-column: span 1 !important;
        aspect-ratio: unset !important;
        padding: 0;
        border-radius: 0;
        overflow: visible;
    }

    .visitors-preview__img {
        display: none;
    }

    .visitors-preview__link {
        display: inline-block;
        width: auto;
        padding: 0;
        font-size: var(--av-font-size);

        &:before,
        &:after {
            content: none;
        }
    }
}
