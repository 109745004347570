html.a11y-vision {

    .filter-popup__block {
        // border: 1px dashed transparent;

        &:focus-within {
            // .focused();
        }

        &:hover {
            // .ally-vision-hover();
        }
    }

    .ss-main {
        border-color: var(--av-color-border-button);
        border-width: 1px;

        &:hover {
            .ally-vision-hover();
            color: green;

            .ss-arrow path {
                stroke: var(--av-color-text) !important;
            }
        }

        .ss-single {
            font-size: var(--av-font-size) !important;
            color: var(--av-color-text) !important;
        }

        .ss-values {

            .ss-single {
                color: var(--av-color-text) !important;
            }
        }
    }

    .ss-content {
        border-radius: 0;
        border: 1px solid var(--av-color-text);

        .ss-list {

            .ss-option {
                font-size: var(--av-font-size);
                color: var(--av-color-text);
                border: 1px solid transparent;

                &:hover {
                    .ally-vision-hover();
                }

                &.ss-selected {
                    background: var(--av-color-text) !important;
                    color: var(--av-color-bg) !important;
                }

                &.ss-highlighted {
                    .ally-vision-hover();
                }
            }
        }
    }
}
