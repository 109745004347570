.price-form {
    background: #fff;
    padding: 60px 30px;
    margin-bottom: 60px;
    h2,
    .h2 {
        margin-top: 0;
    }
    &__form {
        display: flex;
        flex-direction: row;
    }
    &__left {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
    }
}

.price-table {
    margin-bottom: 60px;
    max-width: 1200px;
    h2,
    .h2 {
        margin-bottom: 20px;
    }
    &__wrap {
        padding-left: var(--inner-padding);
        padding-right: var(--inner-padding);
        margin-left: calc(-1 * var(--inner-padding));
        margin-right: calc(-1 * var(--inner-padding));
        overflow-x: auto;
        margin-bottom: 30px;
        td {
            vertical-align: top;
        }
        html:not(.a11y-vision) & {
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }
        table {
            width: 100%;
            &._column-4 {
                tbody td {
                    min-width: 140px;
                }
            }
            &:not(._column-4) {
                td:first-child {
                    width: 70%;
                    min-width: unset;

                    @media (max-width: @screen-s) {
                        width: auto;
                        min-width: 280px;
                    }
                }
                td:last-child {
                    width: 30%;
                    min-width: unset;

                    @media (max-width: @screen-s) {
                        width: auto;
                        min-width: 140px;
                    }
                }
            }
            td {
                font-size: 16px;
                border-bottom: 1px solid #cfcfcf;
            }

            tbody {
                td {
                    min-width: 300px;
                    padding: 17px 14px 14px 14px;
                    
                    &:first-child {
                        padding-left: 30px;
                        padding-right: 30px;
                        border-radius: 50px 0 0 50px;
                        @media (max-width: 992px) {
                            padding-left: 24px;
                        }
                        @media (max-width: 700px) {
                            padding-left: 12px;
                        }
                    }
                    &:last-child {
                        padding-right: 30px;
                        border-radius: 0 50px 50px 0;
                        @media (max-width: 992px) {
                            padding-right: 24px;
                        }
                        @media (max-width: 700px) {
                            padding-right: 12px;
                        }
                    }
                }
            }
            thead {
                td {
                    padding: 12px 10px 10px 10px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    background: #fff;
                    letter-spacing: -0.16px;
                    line-height: 24px;
                    white-space: nowrap;
                    border: 0;
                    &:first-child {
                        padding-left: 30px;
                        border-radius: 50px 0 0 50px;
                        @media (max-width: 992px) {
                            padding-left: 24px;
                        }
                        @media (max-width: 700px) {
                            padding-left: 12px;
                        }
                    }
                    &:last-child {
                        padding-right: 30px;
                        border-radius: 0 50px 50px 0;
                        @media (max-width: 992px) {
                            padding-right: 24px;
                        }
                        @media (max-width: 700px) {
                            padding-right: 12px;
                        }
                    }
                }
            }
        }
    }

    > p {
        max-width: 70%;
        font-size: 0.8em;

        @media (max-width: @screen-l) {
            max-width: 800px;
        }
    }
}

.attention {
    background: #fff;
    padding: 50px 30px;
    max-width: 1200px;
    @media (max-width: 700px) {
        padding: 40px 20px;
    }
    .h2,
    h2 {
        margin-top: 0;
        margin-bottom: 10px;
        max-width: 1000px;

        @media (max-width: @screen-l) {
            max-width: 800px;
        }
    }
    p {
        max-width: 1000px;
        margin-top: 0;

        @media (max-width: @screen-l) {
            max-width: 800px;
        }

        &:last-child {
            margin-bottom: 0;
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
