html.a11y-vision {

    .shema-page {

        .top-row {
            margin-bottom: 2em;
        }

        .overfolow-x__size {
            gap: 0.5em;

            @media (max-width: @screen-s) {
                flex-wrap: wrap;
            }
        }
    }

    .top-row__filters {

        a,
        button {
            border: 1px solid var(--av-color-border-button);
            background: var(--av-color-bg);
            color: var(--av-color-text) !important;
            font-size: var(--av-font-size);

            &:hover {
                .ally-vision-hover();
            }

            &._active {
                background: var(--av-color-text);
                color: var(--av-color-bg) !important;
            }
        }
    }

    .shema {

        .swiper-slide {
            display: block;
        }
    }

    .shema__svg-wrap {
        align-items: flex-start;
        margin-bottom: 2em;

        svg {
            display: block;
            width: 100%;
            max-width: 100%;

            @media (max-width: 880px) {
                height: 14em;
            }
        }
    }

    .shema__svg-legends {
        background: none;
        border: 1px solid var(--av-color-border);
    }

    .shema__svg-legend {
        font-size: var(--av-font-size);
    }

    .shema-page__legends {
        grid-template-columns: 1fr 1fr;

        @media (max-width: @screen-s) {
            grid-template-columns: 1fr;
        }

        li {

            svg {
                width: 1.5em;
                height: 1.5em;
            }
        }
    }
}
