.slider-3 {
    @media (max-width: 1023px) {
        max-width: calc(100% - 20px);
        margin-left: 0 !important;
    }

    @media (max-width: 1200px) {
        overflow: visible !important;
    }
    @media (max-width: 450px) {
        max-width: 100%;
    }
    &._style-2 {
        @media (max-width: 800px) {
            max-width: 90%;
        }
    }
    &__pagi {
    }
}

