// CSS varibles
:root {
    --font-default: Arial, sans-serif;
    --gradient: linear-gradient(93deg, #fb8129 -11.01%, #cd380e 109.58%);
    --gray: #878787;
    --body-bg: #e7e4e5;
    --dark: #0f0f0f;
    --text: #0f0f0f;
    --link: #fb8129;
    --orange: #e65f1d;
    --inner-padding: 32px;
    --hover-red: #e65f1d;
    @media (max-width: 992px) {
        --inner-padding: 23px;
    }
    @media (max-width: 700px) {
        --inner-padding: 12px;
    }
}

// Breakpoints
@screen-xs: 359px;
@screen-s: 700px;
@screen-m: 999px;
@screen-l: 1200px;
@screen-xl: 2000px;
@screen-fix: 2000px;
