html.a11y-vision {

    .guide-preview__img {
        display: none;
    }

    .guide-preview__name {
        font-size: 1.2em;
        font-weight: bold;
        line-height: normal;
    }

    .excursion-prices {
        padding-top: 0;
    }

    .excursion-prices__form {
        margin-top: 2em;
    }

    .excursion-prices__form-title {
        font-size: var(--av-font-size);
    }

    .excursion-prices__form-row,
    .excursion-prices__info,
    .excursion-prices {
        border-color: var(--av-color-border);
    }

    .guide-preview {
        max-width: 100%;
    }

    .white-block:has(.excursion-prices) {
        padding-top: 0;
    }

    .excursion-prices__form-selects {
        flex-wrap: wrap;
    }
}
