.vacancies-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
}
.vacancy {
    padding: 30px 40px 20px;
    background: #fff;
    @media (max-width: 1300px) {
        padding-left: 30px;
        padding-right: 30px;
    }
    @media (max-width: 700px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    &__top {
        position: relative;
        padding-right: 90px;
        padding-bottom: 30px;
        transition: padding 0.3s;
        border-bottom: 1px solid #cfcfcf;
        cursor: pointer;
        @media (max-width: 800px) {
            padding-right: 40px;
        }

        h2,
        .h2 {
            margin-top: 0;
            margin-bottom: 0;
        }
        @media (max-width: 700px) {
            padding-bottom: 0px;
            border-bottom: 0px solid transparent;
            .vacancy._open & {
                padding-bottom: 20px;
                border-bottom: 1px solid #cfcfcf;
            }
        }
    }

    &__arrwow {
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.33203 22.6654L22.6654 9.33203M22.6654 9.33203V22.132M22.6654 9.33203H9.86536' stroke='url(%23paint0_linear_1056_23088)' stroke-width='1.4'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1056_23088' x1='7.5067' y1='13.712' x2='24.5039' y2='14.7486' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FB8129'/%3E%3Cstop offset='1' stop-color='%23CD380E'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        width: 32px;
        height: 32px;
        position: absolute;
        right: 0;
        top: 0;
        transition: transform 0.3s;
        .vacancy._open & {
            transform: rotate(90deg);
        }
        .vacancy__top:hover & {
            transform: rotate(45deg);
        }
    }

    &__body {
        padding-bottom: 20px;
        display: none;
    }

    &__grid {
        @media (min-width: 800px) {
            columns: 2;
            gap: 40px;
        }
    }

    &__block {
        padding-top: 30px;
        page-break-inside: avoid;
        break-inside: avoid;
        padding-bottom: 20px;
        @media (max-width: 992px) {
            padding-top: 20px;
        }
        h2,
        .h2 {
            margin-top: 0;
            color: #878787;
        }
    }

    &__bottom {
        margin-top: -1px;
        border-top: 1px solid #cfcfcf;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        @media (max-width: 1000px) {
            flex-wrap: wrap;
        }
        @media (max-width: 700px) {
            display: none;
            border-top: 0px solid #cfcfcf;
            .vacancy._open & {
                border-top: 1px solid #cfcfcf;
                display: flex;
            }
        }
    }

    &__bottom-list {
        gap: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        @media (max-width: 1000px) {
            width: 100%;
            margin-bottom: 20px;
        }
        @media (max-width: 700px) {
            display: none;
        }
        li {
            position: relative;
            padding-right: 40px;
            &::after {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: var(--gradient);
                display: block;
                content: "";
                top: 50%;
                margin-top: -2px;
                position: absolute;
                right: 18px;
            }
            &:last-child {
                padding-right: 0;
                &::after {
                    display: none;
                }
            }
        }
    }

    &__send {
        margin-left: auto;
        .btn-border__content {
            // background: #fff;
        }
    }
}
