html.a11y-vision {

    .page-podcast {
        background: var(--av-color-bg);
        color: var(--av-color-text);

        .event-preview__hover-link {
            display: block;
            border: 0;
            background: none;
        }

        .event-preview:has(.event-preview__hover-link:hover) {
            .ally-vision-hover();
            border-width: 2px;

            .event-preview__title {
                text-decoration: none;
            }
        }

        .text {

            img {
                display: none;
            }
        }

        .white-block {
            padding-top: 0;
        }
    }

    .podcasts-other {
        grid-template-columns: 1fr;

        .podcast-recent {
            width: 100%;
        }
    }
}
