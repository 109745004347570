.afisha {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 40px 22px;
    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
    &__empty {
        color: var(--text);
        font-family: Circe;
        font-size: 16px;
        display: block;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.16px;
        grid-column: span 2;
        @media (max-width: 600px) {
            grid-column: span 1;
        }
    }
    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px 22px;
        @media (max-width: 1600px) {
            gap: 60px 16px;
        }
        @media (max-width: 992px) {
            gap: 60px 12px;
        }

        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }
    }
}
#afisha-template {
    display: none;
}
.filter-popup {
    &__block-tags {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    @media (max-width: 1024px) {
        position: fixed;
        left: 0;
        z-index: 111;
        top: 0;
        display: none;
        width: 100%;
        height: 100%;
        background: var(--body-bg);
        padding-left: var(--inner-padding);
        padding-right: var(--inner-padding);
        &._open {
            display: block;
        }
        .filter-popup__body {
            max-height: calc(100svh - 160px);
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    &__columns {
        @media (max-width: 1024px) and(min-width:600px) {
            column-gap: 30px;
            columns: 2;
            & > div {
                page-break-inside: avoid;
                break-inside: avoid;
            }
        }
    }
    &__title {
        margin: 0;
        margin-right: auto;
    }
    &__head {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 40px;
        border-bottom: 1px solid #cfcfcf;
        padding-top: 24px;
        padding-bottom: 24px;
    }
    &__close {
        border: 0 solid;
        background: transparent;
        padding: 0;
        margin: 0;
    }
    &__categories {
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        list-style: none;
        align-items: flex-start;
        user-select: none;
        @media (max-width: 1024px) {
            flex-direction: row;
            flex-wrap: wrap;
        }
        @media (max-width: 400px) {
            flex-direction: column;
            flex-wrap: wrap;
        }
        a {
            padding: 11px 20px;
            border-radius: 30px;
            color: var(--text);
            font-family: Circe;
            font-size: 16px;
            display: block;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: -0.16px;
            &._active {
                background: var(--gradient);
                color: #fff !important;
                pointer-events: none;
            }
            html.desktop &:hover {
                color: var(--hover-red);
            }
        }
    }
    &__clear,
    &__sbmt {
        padding: 10px 20px;
        border-radius: 50px;
        display: block;
        width: 100%;
        font-family: Circe;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.14px;
        border: 0 solid;
    }
    &__clear {
        border: 1.5px solid #878787;
        color: #878787;
    }
    &__sbmt {
        background: var(--gradient);
        color: #fff;
        margin-bottom: 15px;
    }
    &__block {
        margin-bottom: 40px;
        &-title {
            font-weight: 700;
            margin-bottom: 20px;
            display: flex;
            cursor: pointer;
            transition: color 0.2s ease;
            &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 10L12 14L16 10' stroke='%230F0F0F' stroke-width='1.4'/%3E%3C/svg%3E%0A");
                width: 24px;
                height: 24px;
                display: inline-block;
                content: "";
                transition: transform 0.3s;
                transform: rotate(-180deg);
            }
            .filter-popup__block._open & {
                &::after {
                    transform: rotate(0deg);
                }
            }
            html.desktop &:hover {
                color: var(--hover-red);
            }
        }
        &-body {
            .checkbox {
                margin-bottom: 10px;
            }
        }
    }
    &__btns {
        margin-top: 40px;
    }
}

.tags-checkbox {
    display: inline-block;
    position: relative;
    border-radius: 50px;
    padding: 8px 12px;
    border: 0 solid;
    box-shadow: inset 0 0 0 1.6px #0f0f0f;
    font-weight: 700;
    backdrop-filter: blur(10px);
    margin-bottom: 10px;
    line-height: 150%;
    letter-spacing: -0.16px;
    display: block;
    transition: all 0.2s ease;
    font-size: 16px;
    input {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: block;
        opacity: 0;
        position: absolute;
    }
    &:has(input:checked) {
        background: var(--gradient);
        color: #fff;
        border: 0 solid;
        box-shadow: 0 0 0 0px #0f0f0f;
    }
    html.desktop &:hover {
        color: var(--hover-red);
        box-shadow: inset 0 0 0 1.6px var(--hover-red);
    }
}
.filter-menu-open {
    width: 100%;
    margin-bottom: 22px;
    color: var(--orange);

    .btn-border__content {
        color: currentColor;
        gap: 5px;
        margin-left: -20px;
    }

    svg {
        path {
            stroke: currentColor;
        }
    }
}
@media (min-width: 1025px) {
    .filter-menu-open,
    .filter-popup__btns,
    .filter-popup__head {
        display: none;
    }
}

