html.a11y-vision {

    .glosarii {

        .overfolow-x,
        .overfolow-x__scroll {
            overflow: visible;
        }

        .overfolow-x__size {
            flex-wrap: wrap;
            gap: 0.5em;

            a {
                border: 1px solid var(--av-color-border-button);
                text-decoration: none;

                &:hover {
                    .ally-vision-hover();
                }

                &._active {
                    background: var(--av-color-text);
                    color: var(--av-color-bg) !important;
                }
            }
        }

        .glosarii-item {
            border-bottom-color: var(--av-color-border);
        }

        .glosarii-item__row {
            grid-template-columns: 1fr;
        }

        .glosarii-item__word {
            font-weight: bold;
            font-size: 1.2em;
        }
    }
}
