html.a11y-vision {

    h1, .h1 {
        font-size: 2.9em;
        line-height: 1.1;
        font-weight: bold;
        margin: 1em 0 1em 0;
        word-wrap: break-word;
        overflow-wrap: break-word;

        @media (max-width: @screen-s) {
            font-size: 1.9em;
        }
    }

    h2, .h2 {
        font-size: 1.7em;
        line-height: 1.2;
        font-weight: bold;
        margin: 1em 0 1em 0;
        word-wrap: break-word;
        overflow-wrap: break-word;

        @media (max-width: @screen-s) {
            font-size: 1.5em;
        }
    }

    p {
        font-size: var(--av-font-size);
    }

    .h1.title-link {
        background: none;

        &:after {
            content: none;
        }
    }

    a.h1.title-link {

        &:hover {
            .ally-vision-hover();
        }
    }

    .text {
        max-width: 100%;

        .btn {
            background: none;
            border: 1px solid var(--av-color-text);
            color: var(--av-color-text);
            text-decoration: none;
            font-size: var(--av-font-size);

            &:hover {
                .ally-vision-hover();
            }

            &:before {
                content: none;
            }

            i {
                display: none;
            }
        }

        ul {

            li {

                &:before {
                    background: currentColor;
                    top: 0.6em;
                }
            }
        }

        p {
            margin: 1em 0;
            text-align: left !important;
            background-color: transparent !important;
            padding: 0 !important;

            big {
                font-family: var(--av-font-family);
                background: none;
                color: var(--av-color-text);
                font-weight: bold;
                font-size: var(--av-font-size);
            }
        }

        h3 {
            font-size: 1.5em;
            font-weight: bold;
        }
    }

    .table-resposive {

        @media (max-width: 992px) {
            padding-left: var(--inner-padding);
            padding-right: var(--inner-padding);
        }
    }

    .blockquote {
        border-left: 2px solid var(--av-color-text);
        margin: 2em 0;
        padding-left: 1em;
        font-size: var(--av-font-size);
    }

    .blockquote__content {
        background: none;
        color: var(--av-color-text);
    }

    small {
        font-size: var(--av-font-size);
    }
}
