html {
    --preloader-animation-f: cubic-bezier(.25,.46,.45,.94);
}

.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 101;
    background: var(--dark);
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader__inner {
    width: 352px;
    height: 292px;

    @media (max-width: @screen-s) {
        width: 176px;
        height: 147px;
    }
}

.preloader__svg {
    display: block;
    width: 100%;
    height: 100%;

    path {
        fill: #fff;
    }

    .preloader__svg-logo {
        opacity: 0;
        transition: all 1.5s ease-in-out 0.5s;
    }

    .preloader__svg-slogan {
        opacity: 0;
        transition: all 1.3s ease-in-out 1.5s;
    }
}

body {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.7s ease;
}


html._is-preloaded {
    .preloader {
        opacity: 0;
        // pointer-events: none;
        visibility: hidden;
        transition: opacity 0.8s ease 3.1s,
                    visibility 0s linear 3.9s;
    }

    body {
        pointer-events: auto;
        opacity: 1;
    }

    .preloader__svg g {
        opacity: 1;
    }

    .hp-promo__title span,
    .hp-promo__subtitle span,
    .hp-promo__buy-ticket,
    .hp-promo__sertificat {
        opacity: 1 !important;
        transform: translateY(0) !important;
    }

    .hp-space__sun,
    .hp-space__earth,
    .hp-space__moon,
    .hp-space__shadow-inner {
        opacity: 1;
        transform: translateY(0) scale(1) !important;
    }
}


// Main page
.hp-promo__title span {
    opacity: 0;
    transform: translateY(0.5em);
    transition: opacity 1.8s var(--preloader-animation-f) 4s,
                transform 1.8s var(--preloader-animation-f) 4s;

    @media (max-width: @screen-l) {
        transform: translateY(30px);
    }

    @media (max-width: @screen-s) {
        transform: translateY(20px);
    }
}

.hp-promo__subtitle span {
    opacity: 0;
    transform: translateY(0.9em);
    transition: opacity 1.8s var(--preloader-animation-f) 4.3s,
                transform 1.8s var(--preloader-animation-f) 4.3s;

    @media (max-width: @screen-l) {
        transform: translateY(20px);
    }

    @media (max-width: @screen-s) {
        transform: translateY(15px);
    }
}

.hp-promo__buy-ticket {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.8s var(--preloader-animation-f) 4.6s,
                transform 1.8s var(--preloader-animation-f) 4.6s;

    @media (max-width: @screen-s) {
        transform: translateY(15px);
    }
}

.hp-promo__sertificat {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.8s var(--preloader-animation-f) 4.7s,
                transform 1.8s var(--preloader-animation-f) 4.7s;

    @media (max-width: @screen-s) {
        transform: translateY(15px);
    }
}

.hp-space__sun,
.hp-space__earth,
.hp-space__moon {
    opacity: 0;
    transform: translateY(0) scale(0.8);
    transition: opacity 2.0s var(--preloader-animation-f) 3.7s,
                transform 2.0s var(--preloader-animation-f) 3.7s;
}

.hp-space__shadow-inner {
    opacity: 0;
    transform: scale(0.9) translateY(0);
    transition: opacity 2.0s var(--preloader-animation-f) 4.5s,
                transform 2.0s var(--preloader-animation-f) 4.5s;
}
