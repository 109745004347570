html.a11y-vision {

    .faq {
        border-radius: 0;
        overflow: visible;
        padding: 1.5em 0;
        margin: 0;
        border-bottom: 1px solid var(--av-color-border);

        .text {
            overflow: hidden;
        }
    }

    .faq__title {
        color: var(--av-color-text) !important;
        border: 1px solid transparent;
        margin: 0;

        &:hover {
            .ally-vision-hover();
        }
    }
}
