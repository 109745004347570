@import './_promo.less';
@import './_video.less';
@import './_program.less';
@import './_blog.less';
@import './_quiz.less';
@import './_schedule.less';

@screen-hp-large: 1601px;

.page-home {
    background: var(--dark);
    color: #fff;

    .footer {
        z-index: 3;
    }
}

.hp-white-bg {
    margin-left: calc(var(--inner-padding) * -1);
    margin-right: calc(var(--inner-padding) * -1);
    background: var(--body-bg);
    color: var(--dark);
    border-radius: 50px 50px 0 0;
    padding-top: 80px;
    padding-bottom: 50px;
    margin-top: 100px;
    margin-bottom: -50px;
    position: relative;
    z-index: 3;

    @media (min-width: @screen-fix) {
        margin-left: 0;
        margin-right: 0;
        width: calc(100vw * var(--w-correction));
        position: relative;
        left: 50%;
        margin-left: calc(-50vw * var(--w-correction));
    }

    @media (min-width: @screen-hp-large) {
        padding-top: 100px;
    }

    @media (max-width: @screen-l) {
        border-radius: 30px 30px 0 0;
        padding-top: 50px;
        margin-top: 60px;
    }

    @media (max-width: @screen-s) {
        padding-top: 40px;
        margin-top: 70px;
    }
}

.hp-white-bg__inner {
    
    > :first-child {
        margin-top: 0;
    }
}
