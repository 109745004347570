@font-face {
    font-family: "Circe";
    src: url("../fonts/Circe-Light.eot");
    src: local("Circe Light"), local("Circe-Light"),
        url("../fonts/Circe-Light.eot") format("embedded-opentype"),
        url("../fonts/Circe-Light.woff2") format("woff2"),
        url("../fonts/Circe-Light.woff") format("woff"),
        url("../fonts/Circe-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Circe Extra";
    src: url("../fonts/Circe-ExtraBold.eot");
    src: local("Circe Extra Bold"), local("Circe-ExtraBold"),
        url("../fonts/Circe-ExtraBold.eot") format("embedded-opentype"),
        url("../fonts/Circe-ExtraBold.woff2") format("woff2"),
        url("../fonts/Circe-ExtraBold.woff") format("woff"),
        url("../fonts/Circe-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Circe";
    src: url("../fonts/Circe-Thin.eot");
    src: local("Circe Thin"), local("Circe-Thin"),
        url("../fonts/Circe-Thin.eot") format("embedded-opentype"),
        url("../fonts/Circe-Thin.woff2") format("woff2"),
        url("../fonts/Circe-Thin.woff") format("woff"),
        url("../fonts/Circe-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Circe";
    src: url("../fonts/Circe-Regular.eot");
    src: local("Circe"), local("Circe-Regular"),
        url("../fonts/Circe-Regular.eot") format("embedded-opentype"),
        url("../fonts/Circe-Regular.woff2") format("woff2"),
        url("../fonts/Circe-Regular.woff") format("woff"),
        url("../fonts/Circe-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Circe";
    src: url("../fonts/Circe-Bold.eot");
    src: local("Circe Bold"), local("Circe-Bold"),
        url("../fonts/Circe-Bold.eot") format("embedded-opentype"),
        url("../fonts/Circe-Bold.woff2") format("woff2"),
        url("../fonts/Circe-Bold.woff") format("woff"),
        url("../fonts/Circe-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "Unbounded";
    src: url("../fonts/Unbounded-Medium.eot");
    src: local("Unbounded Medium"), local("Unbounded-Medium"),
        url("../fonts/Unbounded-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Unbounded-Medium.woff2") format("woff2"),
        url("../fonts/Unbounded-Medium.woff") format("woff"),
        url("../fonts/Unbounded-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Unbounded";
    src: url("../fonts/Unbounded-Bold.eot");
    src: local("Unbounded Bold"), local("Unbounded-Bold"),
        url("../fonts/Unbounded-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Unbounded-Bold.woff2") format("woff2"),
        url("../fonts/Unbounded-Bold.woff") format("woff"),
        url("../fonts/Unbounded-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Unbounded";
    src: url("../fonts/Unbounded-Regular.eot");
    src: local("Unbounded Regular"), local("Unbounded-Regular"),
        url("../fonts/Unbounded-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Unbounded-Regular.woff2") format("woff2"),
        url("../fonts/Unbounded-Regular.woff") format("woff"),
        url("../fonts/Unbounded-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Unbounded";
    src: url("../fonts/Unbounded-Black.eot");
    src: local("Unbounded Black"), local("Unbounded-Black"),
        url("../fonts/Unbounded-Black.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Unbounded-Black.woff2") format("woff2"),
        url("../fonts/Unbounded-Black.woff") format("woff"),
        url("../fonts/Unbounded-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Unbounded";
    src: url("../fonts/Unbounded-Light.eot");
    src: local("Unbounded Light"), local("Unbounded-Light"),
        url("../fonts/Unbounded-Light.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Unbounded-Light.woff2") format("woff2"),
        url("../fonts/Unbounded-Light.woff") format("woff"),
        url("../fonts/Unbounded-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Unbounded Blond";
    src: url("../fonts/Unbounded-Blond.eot");
    src: local("Unbounded Blond"), local("Unbounded-Blond"),
        url("../fonts/Unbounded-Blond.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Unbounded-Blond.woff2") format("woff2"),
        url("../fonts/Unbounded-Blond.woff") format("woff"),
        url("../fonts/Unbounded-Blond.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

