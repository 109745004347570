html.a11y-vision {

    .afisha {
        grid-template-columns: 1fr;
    }

    .afisha__grid {
        grid-template-columns: 1fr;
    }

    .filter-popup__categories {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5em;
        margin-bottom: 4em;

        a {
            text-decoration: none;
            font-size: var(--av-font-size);
            border: 1px solid currentColor;

            &:hover {
                .ally-vision-hover();
            }

            &._active {
                background: var(--av-color-text);
            }
        }
    }

    .overfolow-x__prev,
    .overfolow-x__next {
        display: none;
    }

    .filter-popup__block {
        width: 100%;
    }
    
    .filter-popup__block-title {
        display: inline-flex;
        pointer-events: none;

        &:after {
            content: none;
        }
    }

    .filter-popup__block-body {
        display: flex !important;
        flex-wrap: wrap;
        gap: 0.5em 1.5em;

        @media (max-width: 1024px) {
            flex-direction: column;
        }
    }

    .filter-popup__clear {
        font-size: var(--av-font-size);
    }

    .filter-popup__columns {
        display: flex;
        flex-wrap: wrap;
        columns: unset;
    }

    .filter-popup__block-tags {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5em;
    }

    .tags-checkbox {
        font-size: var(--av-font-size);
        padding: 0.5em 1em;
        box-shadow: none;
        border: 1px solid currentColor;

        &:hover {            
            .ally-vision-hover();            
            box-shadow: none;
        }

        &:has(input:checked) {
            background: var(--av-color-text) !important;
            color: var(--av-color-bg) !important;
        }
    }
}
