.a11y-vision-menu-popup {
    display: none;
    position: fixed;
    left: 0;
    z-index: 111;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--body-bg);
    padding-left: var(--inner-padding);
    padding-right: var(--inner-padding);
    
    &__title {
        margin: 0 !important;
        margin-right: auto;
    }
    &__head {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        border-bottom: 1px solid #cfcfcf;
        padding-top: 24px;
        padding-bottom: 24px;
        gap: 10px;
    }
    &__body {
        max-height: calc(100svh - 100px) !important;
        overflow-x: hidden;
        overflow-y: auto;
        margin-right: calc(var(--inner-padding) * -2);
        padding-right: calc(var(--inner-padding) * 2);
    }
    &__close {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        border: 1px solid currentColor;
        border-radius: 50%;
        box-sizing: border-box;
        padding: 0;
        background: none;
        margin: 0;

        &:hover {
            .ally-vision-hover();
        }

        svg {
            width: 140%;
            height: 140%;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -70% 0 0 -70%;
        }
    }
}

html.a11y-vision {

    .a11y-vision-menu-popup {

        &._is-open {

            @media (max-width: 1024px) {
                display: block;
            }
        }
    }
}

.a11y-vision-menu-popup__full-menu-list {
    display: grid;
    list-style: none;
    margin: 0;
    padding: 1em 0;
    grid-template-columns: 1fr;
    gap: 2em 3em;

    > li {

        > a {
            display: inline-block;
            font-weight: bold;
            // text-decoration: none !important;
            font-size: 1.2em;
            padding: 0 0.2em;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            gap: 0.5em 2em;
            margin: 1em 0 0 0;
            padding: 0;

            @media (max-width: @screen-s) {
                flex-direction: column;
                gap: 1em;
            }

            a {
                display: inline-block;
                // text-decoration: none !important;
                padding: 0 0.2em;
            }

            li._is-active {

                > a {
                    background: #000;
                    color: #fff !important;

                    &:hover {
                        color: var(--av-color-text) !important;
                        background: rgba(0,0,0,0.07);
                    }
                }
            }
        }
    }
}
