html.a11y-vision {

    .slider-3 {

        &._style-2 {
            display: block;
            overflow: visible;
            width: 100%;
            max-width: 100%;
        }
        
        .swiper-wrapper {
            display: block;
            width: 100% !important;
        }

        .swiper-slide {
            width: 100% !important;
            margin-right: 0 !important;
            
            ~ .swiper-slide {
                margin-top: 1em;
            }
        }
    }

    .guides-swiper,
    .sidebar-cards,
    .event-cards,
    .slider-3,
    .awards-slider,
    .event-programs,
    .podcasts-recent .swiper,
    .hp-blog .swiper {
        max-width: 100%;

        .swiper-wrapper {
            flex-wrap: wrap;
            gap: 2em;
            width: 100% !important;
            max-width: 100%;
            transform: none !important;
        }

        .swiper-slide {
            width: 100% !important;
            max-width: 100%;
        }

        .swiper-pagination-horizontal {
            display: none;
        }
    }
}
