html.a11y-vision {

    .feeback__row {
        grid-template-columns: 1fr;

        form {
            max-width: 700px;
        }
    }

    .checkbox,
    .radio {
        display: inline-flex;
        align-items: center;
        border: 1px solid transparent;
        padding: 0.1em 0.2em 0.1em 38px;
        
        &:hover {
            color: var(--av-color-text);
            .ally-vision-hover();
        }

        input:checked + .checkbox__indicator,
        input:checked + .radio__indicator {
            background: var(--av-color-text);
        }

        input.just-validate-error-field + .checkbox__indicator,
        input.just-validate-error-field + .radio__indicator {
            box-shadow: 0 0 0 1px red;
        }
    }

    .checkbox__text {
        font-size: var(--av-font-size);
        font-weight: normal;
    }

    .checkbox__indicator {
        top: 0.1em;
        left: 0.1em;
        box-shadow: 0 0 0 1px var(--av-color-text);
    }

    .form-item {

        input[type="text"],
        input:not([type]) {
            border-color: var(--av-color-border);
            border-radius: 0;
            font-size: var(--av-font-size);
            background: none;
            font-weight: normal;

            &.just-validate-error-field {
                border-color: red;
            }
        }
    }

    .feeback__row2 {
        grid-template-columns: 1fr;
        gap: 3em;
    }

    .feeback__badge {
        background: none;
        border: 2px solid var(--av-color-text);
        color: var(--av-color-text);
        width: 100%;
        height: auto;

        p {
            color: currentColor;
        }
    }

    .form-item-row-two {
        grid-template-columns: 1fr;
    }

    .feeback__left {

        h3 {
            font-size: 1.3em;
            font-weight: bold;
        }

        .checkbox {
            display: flex;
        }
    }

    .air-datepicker {
        font-size: var(--av-font-size);
    }

    .air-datepicker-nav--title {

        &:hover {
            .ally-vision-hover();
        }

        i {
            color: currentColor;
        }
    }

    .air-datepicker-body--day-names {
        font-weight: bold;
        color: var(--av-color-text);
    }

    .air-datepicker-body--day-name {
        color: currentColor;
    }

    .air-datepicker-nav--action {
        border: 1px solid transparent;

        &:hover {
            .ally-vision-hover();
        }

        path {
            stroke: currentColor;
        }
    }

    .air-datepicker-cell {
        border: 1px solid transparent;
        box-sizing: border-box;

        &.-current- {
            font-weight: bold;
            color: var(--av-color-text);
        }

        &.-focus- {
            .ally-vision-hover();
        }
    }

    .radio-row {
        flex-direction: column;
        gap: 0.5em;
    }

    .radio-row__label {
        font-weight: normal;
        font-size: var(--av-font-size);
    }

    .radio-row__row {
        flex-direction: column;
        align-items: flex-start;
    }

    .radio__text {
        font-weight: normal;
        font-size: var(--av-font-size);
        color: var(--av-color-text) !important;
    }

    .radio__indicator {
        top: 0.1em;
        left: 0.1em;
        box-shadow: 0 0 0 1px var(--av-color-text);

        &:after {
            background: var(--av-color-bg);
        }
    }

    .form-success__img {
        display: none;
    }

    .form-success  {

        .text {

            p {
                font-size: var(--av-font-size);
            }
        }
    }
}
