html.a11y-vision {

    .page-search {

        .wrap-page {

            &:before,
            &:after {
                content: none;
            }
        }
    }

    .search-form__field {
        border: 1px solid var(--av-color-text);
        padding: 0.5em 0.5em;
        font-size: 1.3em;
    }

    .search-form__submit {
        width: auto;
        height: auto;
        padding: 0.5em 1em;
        border-radius: 2em;
        border: 1px solid var(--av-color-border-button);
        font-size: var(--av-font-size);
        color: currentColor;
        text-decoration: none;
        font-weight: bold;
        position: relative;
        font-size: var(--av-font-size);
        margin-top: 1em;

        &:hover {
            .ally-vision-hover();
        }

        svg {
            display: none;
        }

        span {
            position: relative;
            overflow: visible;
            margin: auto;
            width: unset;
            height: unset;
            top: 0;
            left: 0;
            clip: unset;
        }
    }

    .search-result {
        grid-template-columns: 1fr;
        gap: 2em;
    }

    .search-result__filter {
        grid-column: unset;
    }

    .search-result__filter-list {

        a {
            display: inline-block;
            transition: none !important;

            &._is-active {
                text-decoration: none;
            }

            span {
                display: inline;
            }
        }
    }

    .search-result__items {
        grid-column: unset;
    }

    .search-item {
        padding: 1.2em;
        border-radius: 1em;
        border: 2px solid var(--av-color-border);
        box-sizing: border-box;
        grid-template-columns: 1fr;
        grid-template-areas: unset;
        margin-bottom: 2em;

        @media (max-width: @screen-s) {
            padding: 0.8em;
        }
    }

    .search-item__tag {
        border: 1px solid var(--av-color-border);
        background: none;
        color: var(--av-color-text);
        font-size: var(--av-font-size);
        height: auto;
        line-height: normal;
        padding: 0.2em 0.5em;
        border-radius: 1em;
        white-space: normal;

        &:before {
            content: none;
        }
    }

    .search-item__title {
        font-size: 1.3em;
        font-weight: bold;

        a {
            background: none;
            transition: none !important;
        }
    }

    .search-empty {
        height: auto;
        text-align: left;

        .h1 {
            font-size: 1.5em;
        }
    }
}
