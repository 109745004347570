html.a11y-vision {

    .page-history {
        background: var(--av-color-bg);
        color: var(--av-color-text);

        .white-block {
            padding: 0 !important;
            
            p {
                color: currentColor !important;
            }
        }

        .text-center {
            text-align: left;
        }

        p {
            color: currentColor;
            max-width: 100%;
        }

        img {
            display: block;
            width: 100%;
            max-width: 100%;

            &._br {
                border-radius: 1em !important;
            }

            + .sup-line {
                margin-top: 4em;
            }
        }

        h1, .h1 {
            margin: 0.5em 0 0.5em 0;
            max-width: 100%;
            color: currentColor !important;

            span,
            color {
                color: currentColor;
            }
        }

        .black-block {

            .history-bg-block__content {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .white-block,
        .black-block {
            width: 100%;
            left: 0;
            margin: 0;
            padding: 0;
            transform: none;

            > div {
                width: min(1100px, 100%);
                margin-left: auto;
                margin-right: auto;
            }
        }

        .svg-light {
            display: none;
        }

        div:has(.black-block) + div:has(.white-block),
        div:has(.white-block) + div:has(.black-block) {
            margin-top: 0;

            .black-block,
            .white-block {
                padding-top: 0;
            }

            @media (max-width: 1300px) {
                margin-top: 0;

                .black-block,
                .white-block {
                    padding-top: 0;
                }
            }
        }

        .h1:not(.history-5__year):not(.h1-big) {

            @media (max-width: 700px) {
                font-size: 1em !important;
                line-height: 1.3 !important;
            }
        }
    }

    .history-1__right,
    .history-bg-block__bg,
    .quote-person__img {
        display: none !important;
    }

    .h1-big {
        text-align: left;
        color: currentColor;
        
        span {
            color: currentColor;
        }
    }

    .history-img-row {
        grid-template-columns: 1fr;
    }

    .sup-line {
        color: currentColor;
        background: none;
        -webkit-text-fill-color: currentColor;
        font-size: var(--av-font-size);
        line-height: normal !important;

        + h1,
        + .h1,
        + .h1-big,
        + h2,
        + .h2 {
            margin-top: 0;
        }
    }

    .history-1 {
        margin-top: 3em;
    }

    .history-1__row {
        grid-template-columns: 1fr;
    }

    .history-1__row2 {

        > div {
            max-width: 100%;
        }
    }

    .history-img-row__item {

        > * {
            max-width: 100%;
        }

        p {
            color: currentColor;
        }
    }

    .history-img-row__img {
        border-radius: 1em !important;
        margin-bottom: 1em;
    }

    .history-img-row__right {
        max-width: 100%;
    }

    .history-bg-block {
        border-radius: 0 !important;

        p {
            color: var(--av-color-text);
        }
    }

    .history-bg-block__content {
        min-height: 0;
        padding-left: 0;
        padding-right: 0;

        @media (max-width: 1400px) {
            padding-left: var(--inner-padding);
            padding-right: var(--inner-padding);
        }

        .h1 {
            margin-top: 0;
        }
    }

    .history-bg-block__bottom {
        grid-template-columns: 1fr;
        margin-top: 0;
    }

    .history-bg-block__row {
        max-width: 100%;
        grid-template-columns: 1fr;
    }

    .history-3__grid {
        grid-template-columns: 1fr;

        .history-3__column {
            grid-column: unset;

            > :first-child {
                margin-top: 0;
            }
        }
    }

    .quote-person {
        padding-left: 0;
        color: currentColor;

        p, color {
            color: var(--av-color-text) !important;
            -webkit-text-fill-color: var(--av-color-text) !important;
        }

        color {
            font-weight: bold;
        }
    }

    .history-4 {

        p {
            color: var(--av-color-text);
        }
    }
    
    .history-4__content {   

        > div > * {
            max-width: 100%;
        }
        
    }

    .history-4__row {
        grid-template-columns: 1fr;
    }

    .history-5__content {
        padding-top: 0;
        min-height: 0;

        p {
            max-width: 100%;
        }
    }

    .history-5__years {
        max-width: 100%;
        grid-template-columns: 1fr;
        list-style: none;
        gap: 1.5em;
        margin-top: 2em;

        li {
            aspect-ratio: unset;
            margin: 0;
            padding: 0;
            border-radius: 0 !important;
            overflow: visible !important;
            backdrop-filter: none;
            border: 0;
        }

        @media (max-width: 700px) {
            display: grid !important;
        }
    }

    .history-5__img {
        position: relative;
        height: auto;

        @media (max-width: 700px) {
            width: 100% !important;
            left: 0;
            margin-left: 0;
        }

        img {
            width: 100%;
            height: auto;
            position: relative;
            left: 0;
            transform: none;
        }
    }

    .history-6 {

        p {
            color: var(--av-color-text) !important;
        }
    }

    .history-6__row {
        grid-template-columns: 1fr;
    }

    .anchor-block {
        margin-bottom: 3em;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .anchor-block__list {
        position: relative !important;
        inset: unset !important;
        width: 100% !important;
        flex-wrap: wrap;
        gap: 0.5em;
        top: 0 !important;
        transform: none !important;
        padding: 0;
        border-radius: 0;
        background: none !important;

        a {
            border: 1px solid var(--av-color-border-button);
            text-decoration: none;

            &:hover {
                .ally-vision-hover();
            }

            &._active {
                background: var(--av-color-text) !important;
                color: var(--av-color-bg) !important;
            }
        }
    }

    .anchor-block__img,
    .anchor-block__tags {
        display: none;
    }

    .scrollmagic-pin-spacer {
        height: auto !important;
        inset: unset !important;
        width: 100% !important;
        position: relative !important;
    }
}
