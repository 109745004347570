.news-card {
    &__link {
        display: block;
    }
    &__img-wrap {
        aspect-ratio: 1/1;
        position: relative;
        margin-bottom: 24px;
        overflow: hidden;
    }
    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        display: block;
        transition: transform 1s ease-out;
    }
    &__date {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 133.333%;
        margin-bottom: 7px;
        letter-spacing: -0.16px;
    }
    &__title {
        margin-top: 7px;
        margin-bottom: 7px;
        padding-right: 20px;
        .color();
        color: var(--dark);
        -webkit-text-fill-color: unset;
        transition: color 0.2s ease;
    }
    &__desc {
        padding-right: 20px;
        
        p {
            margin: 0;
        }
    }
    
    html.desktop & {
        &__link:hover {
            .news-card__img {
                transform: scale(1.04);
            }

            .news-card__title {
                color: transparent
            }
        }
    }
}
