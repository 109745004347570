html.a11y-vision {

    .partners-grid {
        grid-template-columns: 1fr;
        gap: 3em;
    }

    .partner__img-wrap {
        display: none;
    }

    .partner__link {
        display: inline-flex;
        font-size: var(--av-font-size);

        .icon-web {
            display: none;
        }
    }

    .partner__title {
        margin-bottom: 0.5em;
        margin-top: 0;
    }
}
